import styled from "styled-components";
import {
  Container,
  HorizontalSeperator,
  LongBox,
  ShortBox,
} from "../containers/boxes.styled";
import {
  CommonHoldersTable,
  CommonHoldersChart,
  CommonHoldersMobile,
  HolderCompareCoins,
  HolderDistCharts,
  HolderSizeTableDesktop,
} from "./holderCompareMobile";
import MostCompared from "../misc/mostCompared/mostCompared";
import { Column, FullRow, Row } from "../containers/flexbox.styled";
import { theme } from "../theme.styled";
import { borderRads, borderWidths, textSizes } from "../sizes";
import { HolderCompareCoinsContext } from "../../context";
import { useContext, useEffect, useState } from "react";
import { Header, Paragraph, SmallText } from "../texts.styled";
import { paddings } from "../paddings.styled";
import HolderDistChartWrapper from "./holderDistChart";
import SelectMenu from "../misc/selectMenu";
import BarChartWrapper from "../dedicatedCoin/simpleChartBar";
import {
  formatNumber,
  getSmallNumber,
  graphFormatter,
} from "../../util/numberFormatter";
import { RouteLink } from "../../routing/routeLink";

//empty react component
export default function HolderCompareDesktop() {
  const { coin1Data, coin2Data, compareData } = useContext(
    HolderCompareCoinsContext
  );

  return (
    <StyledHolderCompareDesktopContainer>
      <MostCompared />
      <StyledHolderCompareDesktop>
        <HolderCompareSingleCoin coin={coin1Data} coinNo={1} />
        <StyledHolderCompareDesktopMainInfo>
          <HolderCompareCoins />
          <CommonHoldersDesktop />
          <HolderSizeTableDesktop type="common" />
          <CommonHoldersChart isDesktop={true} />
          <SmallText
            color={"rgba(255,255,255,0.5  )"}
            size={textSizes.fontSizes.SMALLER}
            style={{ textAlign: "center" }}
          >
            *This comparison was made based on wallets with balance more than
            $5.
          </SmallText>
        </StyledHolderCompareDesktopMainInfo>
        <HolderCompareSingleCoin coin={coin2Data} coinNo={2} />
      </StyledHolderCompareDesktop>
      <Column gap="0.5rem" width="100%" style={{ marginTop: "1.5rem" }}>
        <Paragraph>COMMON HOLDERS</Paragraph>
        {compareData.commonHolders.length > 0 ? (
          <CommonHoldersTable isDesktop={true} />
        ) : (
          <FullRow justify="center" style={{ padding: "1rem" }}>
            <SmallText color={theme.colors.LIGHT_TEXT}>
              No common holders.
            </SmallText>
          </FullRow>
        )}
      </Column>
    </StyledHolderCompareDesktopContainer>
  );
}

export function HolderCompareSingleCoin({ coin, coinNo }) {
  const { compareData } = useContext(HolderCompareCoinsContext);

  return (
    <Column className={"holder-compare-single-coin"} gap="0.5rem">
      <RouteLink
        to={`/coin/${coin.id}`}
        style={{ width: "100%" }}
        key={coin.id}
        className="holdercomparesinglecoin-link"
      >
        <FullRow
          justify="space-between"
          gap="0.5rem"
          style={{
            padding: "1rem",
            backgroundColor: theme.colors.LEVEL2,
            borderRadius: borderRads.SMALL,
          }}
        >
          <Column gap="0.25rem" width="7rem" align="start">
            <SmallText color={theme.colors.LIGHT_TEXT}>Price</SmallText>
            <SmallText color={theme.colors.LIGHT_TEXT}>
              {coin.price < 0.001 ? (
                <span>
                  $0.0
                  <SmallText size={"0.65rem"}>
                    {getSmallNumber(coin.price).zeroCount}
                  </SmallText>
                  {getSmallNumber(coin.price).nonZeroDigits}
                </span>
              ) : (
                <span>${formatNumber(coin.price)}</span>
              )}
            </SmallText>
          </Column>
          <Row gap="0.5rem" width="fit-content !important">
            <img
              style={{
                width: "1.5rem",
                height: "1.5rem",
              }}
              src={coin.image}
              alt={coin.coinName}
            />
            <Header>{coin.symbol.toUpperCase()}</Header>
          </Row>
          <Column gap="0.25rem" width="7rem" align="end">
            <SmallText color={theme.colors.LIGHT_TEXT}>Market Cap</SmallText>
            <SmallText>${graphFormatter(coin.marketcap)}</SmallText>
          </Column>
        </FullRow>
      </RouteLink>
      <HolderSizeTableDesktop type={"coin" + coinNo.toString()} />

      <HolderCompareDesktopHolderDist coin={coin} coinNo={coinNo} />
      <BarChartWrapper
        coinId={coin.id}
        coin={coin}
        isHoldersDesktop={true}
        key={coin.id}
      />
    </Column>
  );
}

export function HolderCompareDesktopHolderDist({ coin, coinNo, isMobile }) {
  const { coin1Data, coin2Data, compareData } = useContext(
    HolderCompareCoinsContext
  );

  const [top, setTop] = useState("Top 10");

  const [holderData, setHolderData] = useState({
    topHoldersOwned: null,
    otherHoldersOwned: null,
    topHoldersPercentage: null,
    otherHoldersPercentage: null,
  });

  useEffect(() => {
    if (compareData) {
      if (top === "Top 10") {
        setHolderData({
          topHoldersOwned: compareData["top10Totalcoin" + coinNo],
          otherHoldersOwned:
            compareData[`coin${coinNo}CircSupply`] -
            compareData["top10Totalcoin" + coinNo],
          topHoldersPercentage:
            (compareData["top10Totalcoin" + coinNo] /
              compareData[`coin${coinNo}CircSupply`]) *
            100,
          otherHoldersPercentage:
            ((compareData[`coin${coinNo}CircSupply`] -
              compareData["top10Totalcoin" + coinNo]) /
              compareData[`coin${coinNo}CircSupply`]) *
            100,
        });
      } else if (top === "Top 50") {
        setHolderData({
          topHoldersOwned: compareData["top50Totalcoin" + coinNo],
          otherHoldersOwned:
            compareData[`coin${coinNo}CircSupply`] -
            compareData["top50Totalcoin" + coinNo],
          topHoldersPercentage:
            (compareData["top50Totalcoin" + coinNo] /
              compareData[`coin${coinNo}CircSupply`]) *
            100,
          otherHoldersPercentage:
            ((compareData[`coin${coinNo}CircSupply`] -
              compareData["top50Totalcoin" + coinNo]) /
              compareData[`coin${coinNo}CircSupply`]) *
            100,
        });
      } else if (top === "Top 100") {
        setHolderData({
          topHoldersOwned: compareData["top100Totalcoin" + coinNo],
          otherHoldersOwned:
            compareData[`coin${coinNo}CircSupply`] -
            compareData["top100Totalcoin" + coinNo],
          topHoldersPercentage:
            (compareData["top100Totalcoin" + coinNo] /
              compareData[`coin${coinNo}CircSupply`]) *
            100,
          otherHoldersPercentage:
            ((compareData[`coin${coinNo}CircSupply`] -
              compareData["top100Totalcoin" + coinNo]) /
              compareData[`coin${coinNo}CircSupply`]) *
            100,
        });
      }
    }
  }, [top]);

  return (
    <Column
      gap={isMobile ? "0" : "0.5rem"}
      width="100%"
      style={{
        backgroundColor: theme.colors.LEVEL,
        borderRadius: borderRads.SMALL,
      }}
    >
      {isMobile && (
        <FullRow
          justify="center"
          gap="0.5rem"
          style={{ paddingLeft: "0.65rem", paddingTop: "0.65rem" }}
        >
          <img
            src={coin.image}
            alt={coin.coinName}
            style={{ width: "1.2rem" }}
          />
          <Paragraph weight={textSizes.fontWeights.SEMI_BOLD}>
            {coin.symbol.toUpperCase()}
          </Paragraph>
        </FullRow>
      )}

      <div className="top-right-select-menu">
        <SelectMenu
          options={[
            { item: "Top 10", degree: 0 },
            { item: "Top 50", degree: 0 },
            { item: "Top 100", degree: 0 },
          ]}
          selected={top}
          setSelected={setTop}
        />
      </div>

      <FullRow
        justify="start"
        gap="1.5rem"
        style={{
          padding: isMobile ? "0rem 1.5rem 0.2rem 1.5rem" : "1.4rem 1rem",
          paddingLeft: "1.5rem",
          paddingBottom: isMobile ? "0.2rem" : "1.2rem",
        }}
      >
        <HolderDistChartWrapper
          coin={coin}
          compareData={compareData}
          coinNo={coinNo}
          top={top}
          width={135}
          height={135}
        />
        <Column align="start" gap="1rem">
          <Column align="start" gap="0.25rem">
            <SmallText color={theme.colors.LIGHT_TEXT}>
              {top} holders own:
            </SmallText>
            <SmallText>
              {graphFormatter(holderData.topHoldersOwned, false, false, true)} (
              {graphFormatter(
                holderData.topHoldersPercentage,
                false,
                false,
                false,
                true
              )}
              %)
            </SmallText>
          </Column>
          <Column align="start" gap="0.25rem">
            <SmallText color={theme.colors.LIGHT_TEXT}>Others own:</SmallText>
            <SmallText>
              {graphFormatter(holderData.otherHoldersOwned, false, false, true)}{" "}
              (
              {graphFormatter(
                holderData.otherHoldersPercentage,
                false,
                false,
                false,
                true
              )}
              %)
            </SmallText>
          </Column>
        </Column>
      </FullRow>
    </Column>
  );
}

export function CommonHoldersDesktop() {
  const { coin1Data, coin2Data, compareData, excludeCex } = useContext(
    HolderCompareCoinsContext
  );

  return (
    <StyledCommonHoldersDesktop justify="space-between">
      <FullRow justify="space-between" style={{ padding: "0.75rem" }}>
        <Column align="start" gap="0.5rem" width="5rem">
          <img src={coin1Data.image} alt={coin1Data.coinName} />
          <Paragraph>{formatNumber(compareData.coin1HolderCount)}</Paragraph>
        </Column>
        <Column width="5rem" gap="0.25rem">
          <SmallText
            style={{ whiteSpace: "nowrap" }}
            color={theme.colors.LIGHT_TEXT}
          >
            COMMON HOLDERS
          </SmallText>
          <Paragraph size={"1.6rem"} weight={textSizes.fontWeights.MEDIUM}>
            {formatNumber(compareData[`topAllCommonCount`])}
          </Paragraph>
        </Column>
        <Column align="end" gap="0.5rem" width="5rem">
          <img src={coin2Data.image} alt={coin2Data.coinName} />
          <Paragraph>{formatNumber(compareData.coin2HolderCount)}</Paragraph>
        </Column>
      </FullRow>
      <HorizontalSeperator width={"100%"} color={"rgba(255,255,255,0.3)"} />
      <FullRow
        className="only-mid-size"
        justify="space-between"
        style={{ padding: "0.5rem 0.75rem" }}
      >
        <Paragraph style={{ width: "5rem" }}>
          ${graphFormatter(coin1Data.marketcap)}
        </Paragraph>
        <SmallText color={theme.colors.LIGHT_TEXT}>MCAP</SmallText>
        <Paragraph style={{ width: "5rem", textAlign: "end" }}>
          ${graphFormatter(coin2Data.marketcap)}
        </Paragraph>
      </FullRow>
      <FullRow justify="space-between" style={{ padding: "0.75rem" }}>
        <Paragraph style={{ width: "5rem" }}>
          $
          {formatNumber(
            coin1Data.marketcap / compareData.coin1HolderCount,
            true,
            false,
            false,
            6,
            true
          )}
        </Paragraph>
        <SmallText color={theme.colors.LIGHT_TEXT}>MCAP/HOLDERS</SmallText>
        <Paragraph style={{ width: "5rem", textAlign: "end" }}>
          $
          {formatNumber(
            coin2Data.marketcap / compareData.coin2HolderCount,
            true,
            false,
            false,
            6,
            true
          )}
        </Paragraph>
      </FullRow>
    </StyledCommonHoldersDesktop>
  );
}

export const StyledCommonHoldersDesktop = styled(Column)`
  background-color: ${theme.colors.LEVEL};
  border-radius: ${borderRads.SMALL};
  width: 100%;

  .only-mid-size {
    display: none;
  }

  @media (max-width: 80rem) {
    .only-mid-size {
      display: flex;
    }
  }

  @media (max-width: 50rem) {
    .only-mid-size {
      display: none;
    }
  }

  border: ${borderWidths.SMALL} solid #0097d1;

  ${Column} {
    height: 100%;
    justify-content: space-between;
    padding: ${paddings.SMALL};
  }

  & > *:first-child {
    background-color: rgba(16, 183, 255, 0.04);
  }

  img {
    width: 1.2rem;
  }
`;

const StyledHolderCompareDesktopMainInfo = styled(Column)`
  background-color: ${theme.colors.LEVEL};
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: ${borderRads.SMALL};
`;

const StyledHolderCompareDesktop = styled(FullRow)`
  gap: 0.5rem;
  justify-content: space-between;
  align-items: start;

  & > *:first-child,
  & > *:nth-child(3) {
    width: 100%;
  }
  & > *:nth-child(2) {
    min-width: 32rem;
    width: 32rem;
  }

  //if the screen is smaller than 70rem, hide the desktop version

  @media (max-width: 90rem) {
    & > *:nth-child(2) {
      min-width: auto;
      width: 100%;
    }
  }

  @media (max-width: 80rem) {
    & > *:nth-child(2) {
      min-width: auto;
      width: 100%;
    }
  }

  @media (max-width: 80rem) {
    flex-wrap: wrap;

    & > *:nth-child(2) {
      width: 100% !important;
      order: 1;
    }
    & > *:nth-child(1) {
      width: 49% !important;
      order: 2;
    }
    & > *:nth-child(3) {
      width: 49% !important;
      order: 3;
    }
  }
`;

const StyledHolderCompareDesktopContainer = styled(Container)`
  @media (max-width: 50rem) {
    display: none;
  }

  .holder-compare-single-coin {
    max-width: 30rem !important;
  }

  @media (max-width: 100rem) {
    .holder-compare-single-coin {
      max-width: 28rem !important;
    }
  }

  @media (max-width: 90rem) {
    .holder-compare-single-coin {
      max-width: 24rem !important;
    }
  }

  @media (max-width: 80rem) {
    .holder-compare-single-coin {
      max-width: none !important;
    }
  }

  @media (max-width: 70rem) {
    padding: 0.75rem;
  }

  @media (max-width: 50rem) {
    padding: 0;
  }
`;
