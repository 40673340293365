import { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Column, FullRow } from "../containers/flexbox.styled";
import { theme } from "../theme.styled";
import { borderRads, textSizes } from "../sizes";
import { SmallText, LightHeader, Paragraph } from "../texts.styled";
import SelectMenu from "../misc/selectMenu";
import { formatNumber, graphFormatter } from "../../util/numberFormatter";
import HolderDistChartWrapper from "../holderCompare/holderDistChart";
import { HolderCompareCoinsContext } from "../../context";
import { VerticalSeperator } from "../containers/boxes.styled";
import { HideOnMobileWrapper } from "../hideOnMobile";
import { paddings } from "../paddings.styled";
import Loader from "../misc/loader";

const StyledHolderWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.LEVEL};
  border-radius: ${borderRads.SMALL};
  overflow: visible;

  .holder-table-header {
    height: 1rem;
  }

  width: 100%;
  background-image: "transparent";
  background-position: center;
  background-size: 100% 100%;
  background-repeat: none;

  @media (min-width: 70rem) {
    .holder-table-header {
      height: 3rem;
    }
  }

  & > *:first-child {
    img {
      height: 2.3rem;
      padding: ${paddings.LOW};
    }
  }
`;

const StyledHolderSizeTable = styled(FullRow)`
  background-color: ${theme.colors.LEVEL};
  border-radius: ${borderRads.SMALL};
  height: 3.5rem;
  overflow: hidden;
  gap: 0;

  .section {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.25rem;
    padding: 0 0.5rem;
    & > *:first-child {
      font-weight: ${textSizes.fontWeights.REGULAR};
    }
  }

  .section:first-child {
    & > * {
      font-weight: ${textSizes.fontWeights.REGULAR};
    }
    white-space: nowrap;
    width: fit-content;
    background-color: ${theme.colors.LEVEL};
    padding: 0 1rem;
  }
`;

export default function DedicatedCoinHolders({ coin, holderData, isLoading }) {
  const [tableHeight, setTableHeight] = useState(0);

  useEffect(() => {
    // Get height of ROI Calculator table
    const roiTable = document.querySelector('.roi-calculator-table');
    if (roiTable) {
      const height = roiTable.offsetHeight;
      setTableHeight(height);
    }
  }, []);

  if (isLoading) {
    return (
      <div style={{
        width: "100%",
        height: tableHeight || 600,
        backgroundColor: theme.colors.LEVEL,
        borderRadius: borderRads.SMALL,
        padding: "2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <LoadingState />
      </div>
    );
  }

  if (!holderData) return null;

  const contextValue = {
    compareData: holderData,
    coin1Data: coin
  };

  return (
    <HolderCompareCoinsContext.Provider value={contextValue}>
      <StyledHolderWrapper>
        <Column gap="0">
          <FullRow
            gap="0rem"
            justify="center"
            className="holder-table-header"
          >
            <HideOnMobileWrapper>
              <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
                <img src={coin.image} alt={coin.symbol} />
              </div>
            </HideOnMobileWrapper>

            <LightHeader weight={textSizes.fontWeights.SEMI_BOLD} size="0.9rem">
              <h2>
                <HideOnMobileWrapper style={{ display: "inline" }}>
                  <span>{coin.symbol.toUpperCase()}</span>
                </HideOnMobileWrapper>
                <span> </span>
                <span style={{
                  fontWeight: textSizes.fontWeights.LIGHT,
                }}>
                  Holder Distribution
                </span>
              </h2>
            </LightHeader>
          </FullRow>
        </Column>

        <SmallText
          style={{
            width: "100%",
            textAlign: "center",
            display: "block",
            marginBottom: "0.2rem",
          }}
          color={"#757575"}
          size={textSizes.fontSizes.SMALLER}
        >
          comparemarketcap.com
        </SmallText>

        <Column gap="0.5rem" width="100%" style={{ padding: "0rem 0.5rem 0.5rem 0.5rem" }}>
          <HolderSizeTable holderData={holderData} />
          <HolderDistribution holderData={holderData} coin={coin} />
        </Column>
      </StyledHolderWrapper>
    </HolderCompareCoinsContext.Provider>
  );
}

export function HolderDistributionMobile({ coin, holderData, isLoading }) {
  const [top, setTop] = useState("Top 10");

  if (isLoading) {
    return (
      <div style={{
        width: "100%",
        backgroundColor: theme.colors.LEVEL,
        borderRadius: borderRads.SMALL,
        padding: "2rem",
        minHeight: "300px"
      }}>
        <LoadingState />
      </div>
    );
  }

  if (!holderData) return null;

  const contextValue = {
    compareData: holderData,
    coin1Data: coin
  };

  const getTopTotal = () => {
    switch (top) {
      case "Top 10":
        return holderData.top10Totalcoin1;
      case "Top 50":
        return holderData.top50Totalcoin1;
      case "Top 100":
        return holderData.top100Totalcoin1;
      default:
        return holderData.top10Totalcoin1;
    }
  };

  const topAmount = getTopTotal();
  const totalSupply = holderData.coin1CircSupply;
  const percentage = ((topAmount / totalSupply) * 100).toFixed(0);
  const othersAmount = totalSupply - topAmount;
  const othersPercentage = (100 - percentage).toFixed(0);

  return (
    <HolderCompareCoinsContext.Provider value={contextValue}>
      <Column
        gap="0.5rem"
        width="100%"
        style={{
          backgroundColor: theme.colors.LEVEL,
          borderRadius: borderRads.SMALL,
        }}
      >
        <FullRow
          justify="center"
          gap="0.5rem"
          style={{
            paddingLeft: "0.65rem",
            paddingTop: "0.65rem"
          }}
        >
          <img
            src={coin.image}
            alt={coin.coinName}
            style={{ width: "1.2rem" }}
          />
          <h2>
            <Paragraph weight={textSizes.fontWeights.SEMI_BOLD}>
              {coin.symbol.toUpperCase()}
              <span style={{
                marginLeft: "0.5rem",
                fontWeight: textSizes.fontWeights.REGULAR
              }}>
                Holder Distribution
              </span>
            </Paragraph>
          </h2>
        </FullRow>

        <StyledHolderSizeTable>
          <div className="section">
            <SmallText>
              Holders <br /> with over
            </SmallText>
          </div>
          <div className="section">
            <SmallText color={theme.colors.LIGHT_TEXT}>$10</SmallText>
            <SmallText>{formatNumber(holderData.coin1MoreThan10, false, true)}</SmallText>
          </div>
          <VerticalSeperator color={"rgba(255,255,255,0.1)"} height={"70%"} />
          <div className="section">
            <SmallText color={theme.colors.LIGHT_TEXT}>$100</SmallText>
            <SmallText>{formatNumber(holderData.coin1MoreThan100, false, true)}</SmallText>
          </div>
          <VerticalSeperator color={"rgba(255,255,255,0.1)"} height={"70%"} />
          <div className="section">
            <SmallText color={theme.colors.LIGHT_TEXT}>$1,000</SmallText>
            <SmallText>{formatNumber(holderData.coin1MoreThan1000, false, true)}</SmallText>
          </div>
          <VerticalSeperator color={"rgba(255,255,255,0.1)"} height={"70%"} />
          <div className="section">
            <SmallText color={theme.colors.LIGHT_TEXT}>$10,000</SmallText>
            <SmallText>{formatNumber(holderData.coin1MoreThan10000, false, true)}</SmallText>
          </div>
        </StyledHolderSizeTable>

        <Column
          gap="0.5rem"
          width="100%"
          style={{
            backgroundColor: theme.colors.LEVEL,
            borderRadius: borderRads.SMALL,
          }}
        >
          <div className="top-right-select-menu" style={{ transform: 'scale(0.8)', transformOrigin: 'top right' }}>
            <SelectMenu
              options={[
                { item: "Top 10", degree: 0 },
                { item: "Top 50", degree: 0 },
                { item: "Top 100", degree: 0 },
              ]}
              selected={top}
              setSelected={setTop}
            />
          </div>

          <FullRow
            justify="start"
            gap="1.5rem"
            style={{
              padding: "0.2rem 1.5rem 0.2rem 1.5rem",
            }}
          >
            <HolderDistChartWrapper
              coin={coin}
              coinNo={1}
              top={top}
              width={135}
              height={135}
            />
            <Column align="start" gap="1rem">
              <Column align="start" gap="0.25rem">
                <SmallText color={theme.colors.LIGHT_TEXT}>
                  {top} holders own:
                </SmallText>
                <SmallText>
                  {graphFormatter(topAmount)} ({percentage}%)
                </SmallText>
              </Column>
              <Column align="start" gap="0.25rem">
                <SmallText color={theme.colors.LIGHT_TEXT}>Others own:</SmallText>
                <SmallText>
                  {graphFormatter(othersAmount)} ({othersPercentage}%)
                </SmallText>
              </Column>
            </Column>
          </FullRow>
        </Column>
      </Column>
    </HolderCompareCoinsContext.Provider>
  );
}

function HolderSizeTable({ holderData }) {
  return (
    <StyledHolderSizeTable>
      <div className="section">
        <SmallText>
          Holders <br /> with over
        </SmallText>
      </div>
      <div className="section">
        <SmallText color={theme.colors.LIGHT_TEXT}>$10</SmallText>
        <SmallText>{formatNumber(holderData.coin1MoreThan10, false, true)}</SmallText>
      </div>
      <VerticalSeperator color={"rgba(255,255,255,0.1)"} height={"70%"} />
      <div className="section">
        <SmallText color={theme.colors.LIGHT_TEXT}>$100</SmallText>
        <SmallText>{formatNumber(holderData.coin1MoreThan100, false, true)}</SmallText>
      </div>
      <VerticalSeperator color={"rgba(255,255,255,0.1)"} height={"70%"} />
      <div className="section">
        <SmallText color={theme.colors.LIGHT_TEXT}>$1,000</SmallText>
        <SmallText>{formatNumber(holderData.coin1MoreThan1000, false, true)}</SmallText>
      </div>
      <VerticalSeperator color={"rgba(255,255,255,0.1)"} height={"70%"} />
      <div className="section">
        <SmallText color={theme.colors.LIGHT_TEXT}>$10,000</SmallText>
        <SmallText>{formatNumber(holderData.coin1MoreThan10000, false, true)}</SmallText>
      </div>
    </StyledHolderSizeTable>
  );
}

function HolderDistribution({ holderData, coin }) {
  const [top, setTop] = useState("Top 10");

  const getTopTotal = () => {
    switch (top) {
      case "Top 10":
        return holderData.top10Totalcoin1;
      case "Top 50":
        return holderData.top50Totalcoin1;
      case "Top 100":
        return holderData.top100Totalcoin1;
      default:
        return holderData.top10Totalcoin1;
    }
  };

  const topAmount = getTopTotal();
  // Use totalSupply if available, otherwise fall back to circSupply
  const supply = holderData.coin1TotalSupply || holderData.coin1CircSupply;
  const percentage = ((topAmount / supply) * 100).toFixed(0);
  const othersAmount = supply - topAmount;
  const othersPercentage = (100 - percentage).toFixed(0);

  return (
    <Column
      gap="0.5rem"
      width="100%"
      style={{
        flex: 1,
        backgroundColor: theme.colors.LEVEL,
        borderRadius: borderRads.SMALL,
      }}
    >
      <div style={{
        padding: "1rem 1rem 0 1rem"
      }}>
        <SelectMenu
          options={[
            { item: "Top 10", degree: 0 },
            { item: "Top 50", degree: 0 },
            { item: "Top 100", degree: 0 },
          ]}
          selected={top}
          setSelected={setTop}
        />
      </div>

      <FullRow
        justify="start"
        gap="1.5rem"
        style={{
          padding: "0.4rem 1rem 1.2rem 1rem",
        }}
      >
        <HolderDistChartWrapper
          coin={coin}
          coinNo={1}
          top={top}
          width={202.5}
          height={202.5}
        />
        <Column align="start" gap="1rem">
          <Column align="start" gap="0.25rem">
            <SmallText color={theme.colors.LIGHT_TEXT}>
              {top} holders own:
            </SmallText>
            <SmallText>
              {graphFormatter(topAmount)} ({percentage}%)
            </SmallText>
          </Column>
          <Column align="start" gap="0.25rem">
            <SmallText color={theme.colors.LIGHT_TEXT}>Others own:</SmallText>
            <SmallText>
              {graphFormatter(othersAmount)} ({othersPercentage}%)
            </SmallText>
          </Column>
        </Column>
      </FullRow>
    </Column>
  );
}

function LoadingState() {
  return (
    <Column
      style={{
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center"
      }}
      gap="2rem"
    >
      <Loader />
      <Paragraph weight={textSizes.fontWeights.BOLD}>
        Getting holder distribution data...
      </Paragraph>
    </Column>
  );
}