import React, { useContext, useEffect, useState } from "react";
import { AllCoinsContext } from "../../context";
import { formatNumber } from "../../util/numberFormatter";
import { Row } from "../containers/flexbox.styled";
import { Paragraph } from "../texts.styled";
import { useQuery } from "@tanstack/react-query";
import { getAllCoinsWithPrice } from "../stream";

export default function NavbarCoinInfo() {
  const allCoins = useContext(AllCoinsContext);
  const targetCoins = ["bitcoin", "ethereum", "solana"];
  const [coins, setCoins] = useState(null);
  const [coinIndex, setCoinIndex] = useState(0);
  const [coin, setCoin] = useState(null);

  const { data, status } = useQuery(
    ["navbarCoinInfo"],
    () => getAllCoinsWithPrice(),
    {
      refetchInterval: 150000,
      onSuccess: (data) => {
        const filteredCoins = data.filter((item) => targetCoins.includes(item.id));
        setCoins(filteredCoins);
        if (!coin) {
          setCoin(filteredCoins[0]);
        }
      },
    }
  );

  useEffect(() => {
    if (!coins || coins.length === 0) return;

    const timer = setTimeout(() => {
      const nextIndex = coinIndex === coins.length - 1 ? 0 : coinIndex + 1;
      setCoinIndex(nextIndex);
      setCoin(coins[nextIndex]);
    }, 2500);

    return () => clearTimeout(timer); // Cleanup timeout
  }, [coinIndex, coins]);

  if (!coin) return null;

  return (
    <Row width="10rem" gap="0.2rem">
      <Paragraph>
        {coin.symbol?.toUpperCase()}: ${formatNumber(coin.price, true)}
      </Paragraph>
    </Row>
  );
}