import { useQuery } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { FiTrendingUp } from "react-icons/fi";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { TrendsContext } from "../../context";
import { RouteLink } from "../../routing/routeLink";
import {
  add3Dots,
  formatNumber,
  getSmallNumber,
  graphFormatter,
  maxLittleNumber,
} from "../../util/numberFormatter";
import { Column, FullRow, Row } from "../containers/flexbox.styled";
import { HideOnMobileWrapper, ShowOnMobileWrapper } from "../hideOnMobile";
import { Icon } from "../icon.styled";
import Loader from "../misc/loader";
import { textSizes } from "../sizes";
import { getTagCoins, getTrending } from "../stream";
import { Header, SmallText } from "../texts.styled";
import { StyledTable, StyledTableWrapper } from "./table.styled";
import TableSwitch from "./tableSwitch";
import { isSocialLinkValid } from "../../validators";
import { TransparentButton } from "../buttons/buttons.styled";
import { BiWorld } from "react-icons/bi";
import { FaRedditAlien, FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { TbBrandFunimation } from "react-icons/tb";

export default function TopMemeCoins({ isExternal, isHome }) {
  const navigate = useNavigate();

  const { data, status } = useQuery(
    ["home-meme-coins"],
    () => getTagCoins("Meme"),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {},
      onError: (error) => {},
    }
  );

  return (
    status === "success" && (
      <StyledTableWrapper>
        <Row
          notFullOnMobile={true}
          gap={"0.5rem"}
          style={{ margin: "0 auto", width: "fit-content" }}
        >
          <Icon size={"1.4rem"} color={"#ffb814"}>
            <TbBrandFunimation />
          </Icon>
          <RouteLink to="/coins/meme">
            <Row gap="0.1rem">
              <Header size="1rem">Top Meme Coins</Header>
              <Icon size={"1.5rem"}>
                <MdKeyboardArrowRight />
              </Icon>
            </Row>
          </RouteLink>
        </Row>
        {status === "success" && (
          <div style={{ overflowX: "auto", width: "100%" }}>
            <ShowOnMobileWrapper></ShowOnMobileWrapper>
            <StyledTable isHome={isHome}>
              <tr>
                <th>#</th>
                <th>Coin</th>
                <th>Price</th>
                <th>1d</th>
                <th>MC</th>
              </tr>
              {data.coins.slice(0, isExternal ? 10 : 100).map((item, index) => (
                <tr key={index} onClick={() => navigate(`/coin/${item.id}`)}>
                  <td>
                    <SmallText>{index + 1}</SmallText>
                  </td>
                  <td>
                    <Row gap={"0.7rem"}>
                      <img alt="logo" src={item.image} />
                      <HideOnMobileWrapper>
                        <SmallText
                          weight={textSizes.fontWeights.SEMI_BOLD}
                          color={({ theme }) => theme.colors.LIGHT_TEXT}
                          //make it ellipsis
                        >
                          {item.symbol.toUpperCase()}
                        </SmallText>
                      </HideOnMobileWrapper>
                      <ShowOnMobileWrapper>
                        <SmallText
                          weight={textSizes.fontWeights.SEMI_BOLD}
                          color={({ theme }) => theme.colors.LIGHT_TEXT}
                        >
                          {isExternal
                            ? add3Dots(item.symbol, 6).toUpperCase()
                            : item.symbol.toUpperCase()}
                        </SmallText>
                      </ShowOnMobileWrapper>
                    </Row>
                  </td>
                  {item.price < 0.001 ? (
                    <td>
                      <SmallText>
                        $0.0
                        <SmallText
                          size={textSizes.fontSizes.MOBILE_LITTLE_NUMBER}
                        >
                          {getSmallNumber(item.price).zeroCount}
                        </SmallText>
                        {getSmallNumber(item.price).nonZeroDigits}
                      </SmallText>
                    </td>
                  ) : (
                    <td>
                      <SmallText>${formatNumber(item.price)}</SmallText>
                    </td>
                  )}

                  <td>
                    <SmallText
                      style={
                        item.change24H
                          ? item.change24H > 1
                            ? { color: "#82dd55" }
                            : { color: "#ff3f3f" }
                          : { color: "#ffffff" }
                      }
                    >
                      {item.change24H
                        ? `${graphFormatter(item.change24H, false, true)}%`
                        : "-"}
                    </SmallText>
                  </td>

                  <td>
                    <SmallText>
                      ${formatNumber(item.marketcap, false, true)}
                    </SmallText>
                  </td>
                </tr>
              ))}
            </StyledTable>
          </div>
        )}
      </StyledTableWrapper>
    )
  );
}
