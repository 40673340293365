//empty react component

import { useQuery } from "@tanstack/react-query";
import { Column, FullRow, Row } from "./containers/flexbox.styled";
import { getCompareCoins, getHomePageCompare } from "./stream";
import { Header, Paragraph, SmallText } from "./texts.styled";
import styled from "styled-components";
import { borderRads, borderWidths, textSizes } from "./sizes";
import {
  formatNumber,
  getSmallNumber,
  graphFormatter,
} from "../util/numberFormatter";
import { TfiAnnouncement } from "react-icons/tfi";
import { Icon } from "./icon.styled";
import React from "react";
import { theme } from "./theme.styled";
import { RouteLink } from "../routing/routeLink";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { IoIosArrowForward } from "react-icons/io";
import { Route } from "react-router-dom";
import { RiMegaphoneLine } from "react-icons/ri";
function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export default function PromotedCompareSection() {
  const { data: comparisons, status } = useQuery(
    ["homepage-compare"],
    getHomePageCompare,
    {
      refetchOnWindowFocus: false,
    }
  );

  if (status !== "success" || !comparisons) {
    return null;
  }

  const shouldBeFake = (item) => {
    return !item.coin1Id?.trim() || !item.coin2Id?.trim() || item.coin1Id === " " || item.coin2Id === " ";
  };

  // Random order only for mobile
  // const randomOrder = shuffle([0, 1, 2]);
  // const randomOrder = [0].concat(shuffle([1, 2]));
  const randomOrder = shuffle([0, 2]).concat([1]);
  // const randomOrder = [0, 1, 2];

  return (
    <>
      <PromotedCompareSectionMobile>
        <Swiper
          modules={[Autoplay, Pagination]}
          pagination={{ clickable: true }}
          loop={true}
          autoplay={{
            delay: 5000,
          }}
          className="mySwiper promotedCompareSwiper"
        >
          {randomOrder.map((index) => (
            <SwiperSlide key={comparisons[index].id}>
              <PromotedCompareCard
                coin1={comparisons[index].coin1Id}
                coin2={comparisons[index].coin2Id}
                isFake={shouldBeFake(comparisons[index])}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </PromotedCompareSectionMobile>
      <PromotedCompareSectionDesktop gap="1rem">
        {comparisons.map((item) => (
          <PromotedCompareCard
            key={item.id}
            coin1={item.coin1Id}
            coin2={item.coin2Id}
            isFake={shouldBeFake(item)}
          />
        ))}
      </PromotedCompareSectionDesktop>
    </>
  );
}

const PromotedCompareSectionMobile = styled(FullRow)`
  @media (min-width: 50rem) {
    display: none;
  }
`;

const PromotedCompareSectionDesktop = styled(FullRow)`
  gap: 1rem;
  //hide scroll bar
  @media (max-width: 50rem) {
    display: none;
  }
`;

function PromotedCompareCard({ coin1, coin2, isFake = false }) {
  const { data, status } = useQuery(
    ["promoted-compare-card", coin1, coin2],
    () => getCompareCoins(coin1, coin2),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {},
    }
  );

  if (isFake) {
    return (
      <RouteLink
        style={{ width: "100%" }}
        to={"/promote?ad=home-page-comparison"}
      >
        <StyledPromotedCompareCard isFake={isFake}>
          <Column style={{ margin: "auto 0" }} gap="1rem">
            <Icon size={"2.5rem"} color={theme.colors.LIGHT_TEXT}>
              <RiMegaphoneLine />
            </Icon>
            <SmallText color={theme.colors.LIGHT_TEXT}>
              Advertise your coin's comparison here!
            </SmallText>
          </Column>
        </StyledPromotedCompareCard>
      </RouteLink>
    );
  }

  return status === "success" ? (
    <StyledPromotedCompareCard>
      <Row
        style={{ width: "fit-content", marginTop: "0.2rem" }}
        gap="0.75rem !important"
      >
        <RouteLink to={`/coin/${data.coin1data.id}`}>
          <Paragraph size="1.2rem" weight={textSizes.fontWeights.SEMI_BOLD}>
            {data.coin1data.symbol.toUpperCase()}
          </Paragraph>
        </RouteLink>

        <Row gap="0">
          <img src={data.coin1data.image} alt={data.coin1data.name} />
          <img src={data.coin2data.image} alt={data.coin2data.name} />
        </Row>

        <RouteLink to={`/coin/${data.coin2data.id}`}>
          <Paragraph size="1.2rem" weight={textSizes.fontWeights.SEMI_BOLD}>
            {data.coin2data.symbol.toUpperCase()}
          </Paragraph>
        </RouteLink>
      </Row>
      <FullRow justify="space-between">
        <SmallText size={textSizes.fontSizes.SMALLER} style={{ width: "50%" }}>
          When {data.coin1data.symbol.toUpperCase()} reaches <br />
          {data.coin2data.symbol.toUpperCase()}'s market cap
        </SmallText>
        <Header weight={textSizes.fontWeights.MEDIUM}>
          $
          {(data.coin2data.marketcap / data.coin1data.marketcap) *
            data.coin1data.price >
          0.01 ? (
            formatNumber(
              (data.coin2data.marketcap / data.coin1data.marketcap) *
                data.coin1data.price,
              true
            )
          ) : (
            <React.Fragment>
              <span>0.0</span>
              <SmallText>
                {
                  getSmallNumber(
                    (data.coin2data.marketcap / data.coin1data.marketcap) *
                      data.coin1data.price
                  ).zeroCount
                }
              </SmallText>
              <span>
                {
                  getSmallNumber(
                    (data.coin2data.marketcap / data.coin1data.marketcap) *
                      data.coin1data.price
                  ).nonZeroDigits
                }
              </span>
            </React.Fragment>
          )}
          <span
            className="promotedCompareTimes"
            style={{ color: theme.colors.GREEN, fontWeight: "400" }}
          >
            {" "}
            (
            {graphFormatter(
              Math.round(data.coin2data.marketcap / data.coin1data.marketcap),
              false,
              true
            )}
            x)
          </span>
        </Header>
      </FullRow>
      <div
        style={{
          position: "absolute",
          right: "0.5rem",
          top: "0.3rem",
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        <RouteLink to={"/promote"}>
          <SmallText
            color={({ theme }) => theme.colors.GOLD2}
            size={"0.63rem"}
            className="promotedText"
          >
            Promoted
          </SmallText>
        </RouteLink>

        <Icon color={({ theme }) => theme.colors.GOLD2} size={"0.6rem"}>
          <TfiAnnouncement />
        </Icon>
      </div>
      <div
        style={{
          position: "absolute",
          left: "0.5rem",
          top: "0.3rem",
          display: "flex",
          alignItems: "center",
          gap: "0.2rem",
        }}
      >
        <RouteLink to={`/compare/${coin1}/${coin2}`}>
          <SmallText
            color={theme.colors.LIGHT_TEXT}
            size={"0.63rem"}
            className="promotedText"
          >
            See in detail
          </SmallText>
        </RouteLink>
        <Icon color={theme.colors.LIGHT_TEXT} size={"0.6rem"}>
          <IoIosArrowForward />
        </Icon>
      </div>
    </StyledPromotedCompareCard>
  ) : (
    <StyledPromotedCompareCard isLoading={true} />
  );
}

const StyledPromotedCompareCard = styled(Column)`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.SECONDARY};

  &:hover {
    background-color: ${({ theme, isFake }) => isFake && theme.colors.LEVEL};
  }

  padding: 1rem;
  height: 8rem;
  border-radius: ${borderRads.SMALL};
  justify-content: space-between;
  border: 0.07rem solid
    ${({ theme, isLoading }) => (isLoading ? "transparent" : theme.colors.GOLD)};

  img {
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 100%;
    border: 0.07rem solid rgba(255, 255, 255, 0.4);

    &:first-child {
      z-index: 1;
      margin-right: -0.5rem;
    }
  }

  ${Header} {
    text-align: right;
  }

  @media (max-width: 90rem) and (min-width: 70rem) {
    ${Header} {
      font-size: 1rem;
    }
    ${SmallText} {
      font-size: 0.7rem;
      white-space: nowrap;
    }
    ${Paragraph} {
      font-size: 1.1rem;
    }
  }

  @media (max-width: 70rem) and (min-width: 50rem) {
    ${Header} {
      font-size: 1rem;
    }
    ${SmallText} {
      font-size: 0.7rem;
      white-space: nowrap;
    }
    ${Paragraph} {
      font-size: 1.1rem;
    }
  }

  @media (max-width: 50rem) {
    height: 7.5rem;

    ${SmallText} {
      font-size: 0.8rem;
      white-space: nowrap;
    }
    ${Paragraph} {
      font-size: 1.1rem;
    }
    ${Header} {
      white-space: nowrap;
    }

    .promotedText {
      font-size: 0.7rem;
    }
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  ${FullRow} {
    align-items: end;
  }
`;
