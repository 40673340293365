import styled from "styled-components";
import { borderRads, borderWidths } from "../sizes";
import { Column, Row } from "../containers/flexbox.styled";

export const StyledDedicatedCoinGraph = styled.div`
  width: 100%;
`;

export const DedicatedCoinGraphWrapper = styled(Column)`
  border-radius: ${borderRads.MEDIUM};
  width: 100%;
  overflow: hidden;
  border: ${borderWidths.MEDIUM} solid ${({ theme }) => theme.colors.LEVEL};
  height: ${({ external }) => (!external ? "22rem" : "21.5rem")};
  background-color: #31263f;
`;

export const GraphMessage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #31263f;
  background-image: radial-gradient(
    rgba(255, 255, 255, 0.2) 0.5px,
    rgba(0, 0, 0) 0.5px
  );
  background-size: 10px 10px;
`;

export const HalvingGraphWrapper = styled(DedicatedCoinGraphWrapper)`
  @media (max-width: 40rem) {
    /* height: 18rem; */
    margin-top: 0;
  }
  margin-top: 1rem;
  height: 18rem;

  ${Row} {
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    img {
      width: 1.2rem;
    }
  }
  background-color: ${({ theme }) => theme.colors.LEVEL};
`;
