import styled from "styled-components";
import React, { useState } from "react";
import { borderRads, borderWidths, textSizes } from "../sizes";
import {
  formatNumber,
  getSmallNumber,
  maxLittleNumber,
} from "../../util/numberFormatter";
import { paddings } from "../paddings.styled";
import { BigText, LightHeader, SmallText } from "../texts.styled";
import { Column, FullRow, Row } from "../containers/flexbox.styled";
import { theme } from "../theme.styled";
import { HideOnMobileWrapper } from "../hideOnMobile";

export function RoiCalculatorTable({ coin }) {
  const maxLines = 10;
  let currentLine = 1;

  const [investment, setInvestment] = useState(500);

  const marketcaps = [
    1000000, 2500000, 5000000, 10000000, 25000000, 50000000, 100000000,
    250000000, 500000000, 750000000, 1000000000, 3000000000, 5000000000,
    15000000000, 50000000000, 90000000000, 100000000000, 200000000000,
    500000000000, 1000000000000, 2000000000000, 3000000000000, 5000000000000,
    7500000000000, 10000000000000, 12500000000000, 15000000000000,
  ];

  function updateInvestment(e) {
    if (e.target.value.toString().length < 6) {
      setInvestment(e.target.value);
    }
  }

  return (
    <StyledRoiCalculatorWrapper className="roi-calculator-table" externalImage={coin.externalROIImage}>
      <Column gap="0">
        <FullRow
          gap="0rem"
          justify="center"
          className="roi-table-header"
          style={{
            margin: "0 auto",
            backgroundColor: coin.externalROIImage
              ? "rgba(0, 0, 0, 0.7)"
              : "transparent",
          }}
        >
          <HideOnMobileWrapper>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={coin.image} />
            </div>
          </HideOnMobileWrapper>

          <LightHeader weight={textSizes.fontWeights.SEMI_BOLD} size="0.9rem">
            <h2>
              <HideOnMobileWrapper style={{ display: "inline" }}>
                <h2></h2>
                <span>{coin.symbol.toUpperCase()}</span>
              </HideOnMobileWrapper>
              <span> </span>{" "}
              <span
                style={{
                  fontWeight: textSizes.fontWeights.LIGHT,
                }}
              >
                ROI Calculator
              </span>
            </h2>
          </LightHeader>
        </FullRow>
      </Column>
      <SmallText
        style={{
          width: "100%",
          textAlign: "center",
          display: "block",
          marginBottom: "0.2rem",
        }}
        color={"#757575"}
        size={textSizes.fontSizes.SMALLER}
      >
        comparemarketcap.com
      </SmallText>
      <StyledRoiCalculatorTable externalImage={coin.externalROIImage}>
        <React.Fragment>
          <tr className="roi-table-h">
            <th>
              <LightHeader>ROI</LightHeader>
            </th>
            <th>
              <LightHeader>Price</LightHeader>
            </th>
            <th>
              <LightHeader>Market Cap</LightHeader>
            </th>
            <th>
              <LightHeader>Investment</LightHeader>
            </th>
          </tr>
          <tr>
            <td>1x</td>
            <td className="only-desktop">
              {coin.price < 0.01 ? (
                <span>
                  $0.0
                  <SmallText size={"0.6rem"}>
                    {getSmallNumber(coin.price).zeroCount}
                  </SmallText>
                  {getSmallNumber(coin.price).nonZeroDigits}
                </span>
              ) : (
                <span>${formatNumber(coin.price)}</span>
              )}
            </td>
            <td className="only-desktop">
              ${formatNumber(coin.marketcap, false, true)}
            </td>
            <td className="roi-investment-input-wrapper">
              <span>$</span>{" "}
              <input
                type="number"
                onChange={(e) => updateInvestment(e)}
                maxLength={5}
                className="roi-investment-input"
                value={investment}
              />
            </td>
          </tr>
          {marketcaps.map((item, index) => {
            if (coin.marketcap < item && currentLine <= maxLines) {
              const ratio = item / coin.marketcap;
              currentLine++;
              return (
                <tr key={index}>
                  <td style={{ color: theme.colors.GREEN }}>
                    {formatNumber(ratio, true, false, true, 6, false, true)}x
                  </td>
                  <td>
                    {coin.price * ratio < 0.01 ? (
                      <span>
                        $0.0
                        <SmallText size={"0.6rem"}>
                          {getSmallNumber(coin.price * ratio).zeroCount}
                        </SmallText>
                        {getSmallNumber(coin.price * ratio).nonZeroDigits}
                      </span>
                    ) : (
                      <span>${formatNumber(coin.price * ratio)}</span>
                    )}
                  </td>
                  <td>${formatNumber(item, false, true)}</td>
                  <td>
                    $
                    {new Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 0,
                    }).format(investment * ratio)}
                  </td>
                </tr>
              );
            }
          })}
        </React.Fragment>
      </StyledRoiCalculatorTable>
      {/* <p style={{ height: "2.9rem" }}></p> */}
    </StyledRoiCalculatorWrapper>
  );
}

export function RoiCalculatorButton({ onClick }) {
  return (
    <StyledRoiCalculatorButton onClick={onClick}>
      <StyledRoiCalculatorButtonInside>
        ROI CALCULATOR
      </StyledRoiCalculatorButtonInside>
    </StyledRoiCalculatorButton>
  );
}

export const StyledRoiCalculatorWrapper = styled.div`
  .roi-table-header {
    height: 1rem;
  }

  width: 100%;
  overflow: auto;
  background-image: "transparent";
  background-position: center;
  background-size: ${({ externalImage }) =>
    externalImage ? "cover" : "100% 100%"};
  background-repeat: none;
  border-radius: ${borderRads.SMALL};

  //add border
  /* border: ${borderWidths.SMALL} solid rgba(255, 255, 255, 0.1); */

  //only if the with is more than 70rem
  @media (min-width: 70rem) {
    .roi-table-header {
      height: 3rem;
    }
    background-color: ${theme.colors.LEVEL};
  }

  & > *:first-child {
    img {
      height: 2.3rem;
      padding: ${paddings.LOW};
    }
  }
`;

export const StyledRoiCalculatorTable = styled.table`
  //add border top

  font-size: 1rem;
  font-weight: normal;
  border-spacing: 0.19rem;
  width: 100%;
  border-collapse: collapse;
  background-position: center;
  background-size: cover;
  overflow: hidden;

  //if smaller than 40rem, add background
  @media (max-width: 40rem) {
    background-color: rgba(255, 255, 255, 0.04);
  }

  td {
    &:first-child {
      border-right: 0.1rem solid ${theme.colors.LEVEL2};
    }

    &:nth-child(2) {
      border-right: 0.1rem solid ${theme.colors.LEVEL2};
      border-left: 0.1rem solid ${theme.colors.LEVEL2};
    }

    &:nth-child(3) {
      border-right: 0.1rem solid ${theme.colors.LEVEL2};
      border-left: 0.1rem solid ${theme.colors.LEVEL2};
    }

    &:last-child {
      border-left: 0.1rem solid ${theme.colors.LEVEL2};
    }
  }

  tr {
    text-align: center;
    border-bottom: 0.05rem solid rgba(255, 255, 255, 0.1);

    &:nth-child(2n) {
      background-color: rgba(255, 255, 255, 0.02);
    }
  }

  tr > *:last-child {
    text-align: end;
  }

  tr > *:first-child {
    text-align: start;
  }

  td {
    padding: 0.4rem 1.5rem;
    font-size: ${textSizes.fontSizes.SMALLER};
    color: rgba(255, 255, 255, 0.7);
    @media (max-width: 40rem) {
      padding: ${paddings.MID} ${paddings.LOW};
      font-size: ${textSizes.fontSizes.SMALLER};
    }

    //for every 2nd td, add a background
  }
  th {
    padding: 0.5rem 1.5rem;
    background-color: rgba(255, 255, 255, 0.07);

    ${LightHeader} {
      font-size: ${textSizes.fontSizes.SMALL};
    }

    border-top: 0.1rem solid ${theme.colors.LEVEL2};
    border-bottom: 0.1rem solid ${theme.colors.LEVEL2};

    &:first-child {
      border-right: 0.1rem solid ${theme.colors.LEVEL2};
    }

    &:nth-child(2) {
      border-right: 0.1rem solid ${theme.colors.LEVEL2};
      border-left: 0.1rem solid ${theme.colors.LEVEL2};
    }

    &:nth-child(3) {
      border-right: 0.1rem solid ${theme.colors.LEVEL2};
      border-left: 0.1rem solid ${theme.colors.LEVEL2};
    }

    &:last-child {
      border-left: 0.1rem solid ${theme.colors.LEVEL2};
    }

    //add seperator

    @media (max-width: 40rem) {
      padding: ${paddings.MID} ${paddings.LOW};
      white-space: nowrap;
      ${LightHeader} {
        font-size: ${textSizes.fontSizes.SMALLER};
      }
    }
  }

  input {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: ${borderRads.SMALL};
    padding: ${paddings.LOWER};
    border: ${borderWidths.SMALL} solid rgba(255, 255, 255, 0.2);
    text-align: end;
    width: 4rem;
    height: 1.5rem;
  }

  & > *:first-child > *:first-child {
    /* border-radius: ${borderRads.SMALL} 0 0 0; */
  }

  & > *:first-child > *:last-child {
    /* border-radius: 0 ${borderRads.SMALL} 0 0; */
  }

  & > *:nth-child(2) > *:last-child {
    padding-top: 0;
    padding-bottom: 0;
  }

  & > *:last-child > *:first-child {
    border-radius: 0 0 0 ${borderRads.SMALL};
  }

  & > *:last-child > *:last-child {
    border-radius: 0 0 ${borderRads.SMALL} 0;
  }
`;

export const StyledRoiCalculatorButton = styled.button`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 0.75rem;
  background: linear-gradient(to right, #711a75, #5f30e7, #4369f2);
  border: none;
  align-self: center;
`;

export const StyledRoiCalculatorButtonInside = styled.div`
  padding: 0.7rem 2rem;
  background-color: ${({ isOn }) =>
    isOn ? "rgba(0,0,0,0)" : "rgba(0,0,0,0.8)"};
  border-radius: 0.7rem;
  transition: 0.5s;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    transition: 0.5s;
  }
`;
