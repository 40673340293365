import { useContext, useEffect, useRef, useState } from "react";
import ReactECharts from "echarts-for-react";
import { defaultCoins } from "../compareSection/defaultCoins";
import Dropdown from "../compareSection/dropdown";
import DropdownMenu from "../compareSection/dropdownMenu";
import { VerticalSeperator } from "../containers/boxes.styled";
import { HorizontalSeperator } from "../containers/boxes.styled";
import { Column, Row } from "../containers/flexbox.styled";
import { BigText, Header, LightHeader, Paragraph, SmallText } from "../texts.styled";
import {
  HalvingBoxDropdownWrapper,
  StyledHalvingBox,
  StyledHalvingBoxBackButton,
  StyledHalvingBoxWrapper,
  TextWrapper,
  InfoRectangle,
  DescContainer,
  InfoMobileRectangle,
  MobileTable,
  MobileTopRectangle,
  InfoTopRectangle,
  InfoBottomRectangle,
  TableRectangle
} from "./halvingBox.styled";
import {
  OutlineButton,
  PrimaryButton,
  TransparentButton,
} from "../buttons/buttons.styled";
import { borderWidths, textSizes } from "../sizes";
import { HideOnMobileWrapper, ShowOnMobileWrapper } from "../hideOnMobile";
import React from "react";
import Loader from "../misc/loader";
import HalvingResultThree, { dateDifferenceInDays } from "./halvingResultThree";
import HalvingGraph from "./halvingGraph";
import { useQuery } from "@tanstack/react-query";
import {
  getCoin,
  getHalvingCoins,
  getHalvingHistoricData,
  getHistoricalDataOfCoin,
} from "../stream";
import {
  AllCoinsContext,
  HalvingContext,
  HalvingDateContext,
} from "../../context";
import { IoChevronBackOutline } from "react-icons/io5";
import { Icon } from "../icon.styled";
import { formatNumber } from "../../util/numberFormatter";
import styled from "styled-components";
import { useTheme } from 'styled-components';


export default function HalvingBox({ externalCoin }) {
  const allCoins = useContext(AllCoinsContext);

  const [coin1, setCoin1] = useState(
    externalCoin
      ? allCoins?.find((item) => item.id === externalCoin)
      : defaultCoins.ethereum
  );
  const [coin2, setCoin2] = useState(defaultCoins.bitcoin);

  const [pressed, setPressed] = useState(false);
  const [mode, setMode] = useState("crypto");  // Add this if not already present

  const handleCoin1Selection = (selectedCoin) => {
    console.log("Setting coin1 to:", selectedCoin);
    setCoin1(selectedCoin);
  };

  const scrollRef = useRef(null);

  function handleCompare() {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    setPressed(true);
  }

  function handleBackButton() {
    setCoin1(defaultCoins.ethereum);
    setCoin2(defaultCoins.bitcoin);
    setPressed(false);
  }

  const { data: halvingCoins, status: halvingCoinsStatus } = useQuery(
    ["halving-coins"],
    () => getHalvingCoins(),
    {
      onSuccess: (data) => { },
      refetchOnWindowFocus: false,
    }
  );

  return (
    <StyledHalvingBoxWrapper ref={scrollRef} pressed={pressed}>
      {pressed && <HalvingBoxBackButton handleBackButton={handleBackButton} />}
      {!pressed ? (
        <HalvingBoxLanding
          halvingCoins={halvingCoins}
          halvingCoinsStatus={halvingCoinsStatus}
          handleCompare={handleCompare}
          setCoin1={handleCoin1Selection}  // Changed this from setCoin1
          setCoin2={setCoin2}
          externalCoin={externalCoin}
        />
      ) : (
        <HalvingBoxResult
          halvingCoins={halvingCoins}
          coin1={coin1}
          coin2={coin2}
          key={new Date()}
        />
      )}
    </StyledHalvingBoxWrapper>
  );
}

function HalvingBoxBackButton({ handleBackButton }) {
  return (
    <StyledHalvingBoxBackButton
      onClick={() => {
        handleBackButton();
      }}
    >
      <TransparentButton>
        <Icon size="2rem">
          <IoChevronBackOutline />
        </Icon>
      </TransparentButton>
      <SmallText>Back</SmallText>
    </StyledHalvingBoxBackButton>
  );
}

function formatPrice(value) {
  // Convert to a number in case it's in string format
  let number = Number(value);

  // Handle exact zero
  if (number === 0) {
    return "$0";
  }

  // Handle price values smaller than $0.01
  if (number < 0.01) {
    const formatted = number.toFixed(10); // Convert to a fixed decimal string with sufficient precision
    const [integerPart, decimalPart] = formatted.split(".");

    // Count leading zeros in the decimal part
    const leadingZeros = decimalPart.match(/^0+/)?.[0]?.length || 0;

    if (leadingZeros > 0) {
      const significantDigits = decimalPart.slice(leadingZeros, leadingZeros + 2); // First two significant digits
      return (
        <span>
          $0.0
          <span style={{ verticalAlign: "sub", fontSize: "0.8em" }}>{leadingZeros}</span>
          {significantDigits}
        </span>
      );
    }

    // If no leading zeros (less likely), just return the formatted price
    return `$${number.toFixed(6)}`;
  }

  // Handle regular prices (>= $0.01)
  return `$${formatNumber(number)}`;
}




function formatMarketCap(value) {
  if (value >= 1e12) {
    return `${(value / 1e12).toFixed(2)}T`;
  } else if (value >= 1e9) {
    return `${(value / 1e9).toFixed(2)}B`;
  } else if (value >= 1e6) {
    return `${(value / 1e6).toFixed(2)}M`;
  }
  return formatNumber(value);
}

function HalvingBoxResult({ coin1, coin2, halvingCoins }) {
  const theme = useTheme();

  //MOBİL KONTROL - LOGO RENDERLANMASI İÇİN
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  //

  const { data: coinData, status: coinStatus } = useQuery(
    ["halving-coin", coin1],
    () => getCoin(coin1.id),
    { refetchOnWindowFocus: false }
  );

  const { data: bitcoinData, status: bitcoinStatus } = useQuery(
    ["halving-bitcoin", coin2.id],
    () => getHalvingHistoricData(coin2.id),
    { refetchOnWindowFocus: false }
  );

  const halvingDate = useContext(HalvingDateContext);
  const daysSinceHalving = dateDifferenceInDays(new Date(halvingDate), new Date());

  const { data: halvingData, status: halvingStatus } = useQuery(
    ["halving-coins"],
    () => getHalvingCoins(),
    {
      onSuccess: (data) => { },
      refetchOnWindowFocus: false,
    }
  );

  if (
    !bitcoinData ||
    bitcoinStatus !== "success" ||
    coinStatus !== "success" ||
    halvingStatus !== "success"
  ) {
    return <Loader height={"30rem"} />;
  }

  // Ensure halvingData is loaded and structured properly
  const selectedHalvingData = halvingData.find((coin) => coin.id === coin2.id);

  if (!selectedHalvingData) {
    console.error(`No halving data found for coin: ${coin2.id}`);
    return <div>Error: Coin data not found</div>;
  }

  // Extract date3 (peak date) and date1 (209-day date)
  const peakDate = selectedHalvingData.date3;
  const halvingDate2020 = "2020-05-11";
  const date209DaysAfterHalving = new Date(new Date(halvingDate2020).getTime() + (daysSinceHalving) * 24 * 60 * 60 * 1000);

  const targetTimestamp = new Date(date209DaysAfterHalving).getTime();
  const targetTimestamp2 = new Date(peakDate).getTime();

  //These are for the pie graph
  const halvingTimestamp = new Date(halvingDate2020).getTime();
  const peakTimestamp = new Date(peakDate).getTime();

  // Calculate days to peak
  const daysToPeak = Math.ceil((peakTimestamp - halvingTimestamp) / (24 * 60 * 60 * 1000)); // Convert ms to days
  const daysLeft = daysToPeak - daysSinceHalving; // Days left to peak

  // Ensure bitcoinData.price and bitcoinData.marketCap are arrays
  const prices = bitcoinData?.price || [];
  const marketCaps = bitcoinData?.marketcap || [];

  // Safely retrieve price and market cap for the 209-day date
  const price209DaysAfterTopCoin = prices.find(
    (data) => data.time === targetTimestamp
  )?.value || 0;

  const marketCap209DaysAfterTopCoin = marketCaps.find(
    (data) => data.x === targetTimestamp
  )?.y || 0;

  // Safely retrieve price and market cap for the peak date
  const peakPriceTopCoin = prices.find((data) => data.time === targetTimestamp2)?.value || 0;

  const peakMarketCapTopCoin = marketCaps.find(
    (data) => data.x === targetTimestamp2
  )?.y || 0;

  // Calculate dynamic multiplier safely
  const dynamicMultiplier = price209DaysAfterTopCoin
    ? peakPriceTopCoin / price209DaysAfterTopCoin
    : 1;

  // Calculate values for the comparison coin
  const initialMarketCapComparisonCoin = coinData?.marketcap || 0;
  const initialPriceComparisonCoin = coinData?.price || 0;
  const finalMarketCapComparisonCoin =
    initialMarketCapComparisonCoin * dynamicMultiplier;
  const finalPriceComparisonCoin =
    initialPriceComparisonCoin * dynamicMultiplier;

  return (
    <HalvingContext.Provider value={{ bitcoinData, coinData, halvingCoins, coin2 }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem', width: '100%' }}>

        <h2 style={{ textAlign: 'center', fontSize: '1.3rem', fontWeight: 'bold', marginBottom: '1rem' }}>
          Halving Comparison
        </h2>

        <DescContainer>
          {/* Left Section: Bitcoin Name and Logo */}
          <div className="left">
            <p>{coin1.symbol.toUpperCase()}</p>
            <div className="logo-wrapper">
              <img className="logo" src={coin1.image} />
            </div>
          </div>

          {/* VS Section */}
          <div className="vs">
            <p>vs</p>
          </div>

          {/* Right Section: Monero Logo and Name */}
          <div className="right">
            <div className="logo-wrapper">
              <img className="logo" src={coin2.image} />
            </div>
            <p>{coin2.symbol.toUpperCase()}</p>
          </div>
        </DescContainer>







        {/* New InfoRectangle Section */}
        <MobileTopRectangle>
          <div>
            <h4>Market Cap</h4>
            <p>${formatMarketCap(coinData?.marketcap)}</p>
          </div>
          <div>
            <img src={coin1.image} alt={`${coin1.coinName} logo`} />
          </div>
          <div>
            <h4>Price</h4>
            <p>{formatPrice(coinData?.price)}</p>
          </div>
        </MobileTopRectangle>
        <InfoRectangle>
          <InfoTopRectangle>
            <div>
              <h4>Market Cap</h4>
              <p>${formatMarketCap(coinData?.marketcap)}</p>
            </div>
            <div>
              <img src={coin1.image} alt={`${coin1.coinName} logo`} />
            </div>
            <div>
              <h4>Price</h4>
              <p>{formatPrice(coinData?.price)}</p>
            </div>
          </InfoTopRectangle>
          <TextWrapper>
            If {coin1.coinName} follows {coin2.coinName}'s 2020 halving<br /> pattern, its price and market cap will be:
          </TextWrapper>
          <p style={{ textAlign: 'center', fontSize: '0.9rem', fontWeight: 'lighter', marginBottom: '0.1rem' }}>
            comparemarketcap.com
          </p>

          <InfoMobileRectangle>
            <div className="top-row">
              <p id="price">{formatPrice(finalPriceComparisonCoin)}</p>
              <p id="mul">({dynamicMultiplier.toFixed(2)}x)</p>
            </div>
            <div className="bottom-row">
              <p id="mcap">Market Cap: ${formatMarketCap(finalMarketCapComparisonCoin)}</p>
            </div>
          </InfoMobileRectangle>

          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <InfoBottomRectangle>
              <div>
                <p id="price">{formatPrice(finalPriceComparisonCoin)}</p>
              </div>
              <div>
                <p id="mcap">M.Cap: ${formatMarketCap(finalMarketCapComparisonCoin)}</p>
              </div>
            </InfoBottomRectangle>
            <InfoBottomRectangle>
              <div>
                <p id="mul">{dynamicMultiplier.toFixed(2)}x</p>
              </div>
            </InfoBottomRectangle>
          </div>
          {/* New TableRectangle */}
          <TableRectangle>
            <div className="rectangle-content">
              {/* Left Section */}
              <div className="left">
                <img
                  src={coin2.image}
                  alt={`${coin2.coinName} logo`}
                  className="icon"
                />
                <div className="description">
                  <p className="title">{coin2.coinName}</p>
                  <p className="subtitle">in 2020 halving</p>
                </div>
              </div>

              {/* Vertical Separator */}
              <VerticalSeperator width="0.05rem" height="4.5rem" />

              {/* Right Section */}
              {/* Right Section */}
              <div className="middle-table">
                <div className="row">
                  <span className="title"></span> {/* Placeholder for alignment */}
                  <span className="title">{daysSinceHalving} days after</span>
                  <span className="title">Peak</span>
                </div>
                <div className="row">
                  <span className="title">Price</span>
                  <span className="text">{formatPrice(price209DaysAfterTopCoin)}</span>
                  <span className="text">{formatPrice(peakPriceTopCoin)}</span>
                </div>
                <div className="row">
                  <span className="title">M.Cap</span>
                  <span className="text">${formatMarketCap(marketCap209DaysAfterTopCoin)}</span>
                  <span className="text">${formatMarketCap(peakMarketCapTopCoin)}</span>
                </div>
              </div>

              {/* Vertical Separator */}
              <VerticalSeperator width="0.05rem" height="4.5rem" />

              <div className="right">
                <ReactECharts
                  option={{
                    series: [
                      {
                        type: "pie",
                        radius: ["70%", "100%"], // Create a circular progress ring
                        startAngle: 90, // Rotate starting point
                        avoidLabelOverlap: false,
                        label: { show: false },
                        labelLine: { show: false },
                        data: [
                          {
                            value: daysSinceHalving, // Green part (progress)
                            itemStyle: { color: "#A6FF55" }, // Green progress
                          },
                          {
                            value: daysToPeak - daysSinceHalving, // Remaining days
                            itemStyle: { color: "rgb(38, 21, 56)" }, // Background arc color
                          },
                        ],
                      },
                    ],
                    graphic: [
                      {
                        type: "text",
                        left: "center",
                        top: "center",
                        style: {
                          text: `${daysLeft}`, // Days left to peak
                          fill: "#FFFFFF", // Text color
                          fontSize: 16, // Font size
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif", // Ensure font matches page
                        },
                      },
                    ],
                  }}
                  style={{ width: "3.6rem", height: "3.6rem" }} // Adjust size as needed
                />
                <span className="chart-text">days to peak</span> {/* Add centered text below */}
              </div>




            </div>

          </TableRectangle>
        </InfoRectangle>

        <MobileTable>
          <div className="rectangle-content">
            {/* Left Section */}
            <div className="top">
              <img
                src={coin2.image}
                alt={`${coin2.coinName} logo`}
                className="icon"
              />
              <div className="description">
                <p className="title">{coin2.symbol.toUpperCase()}</p>
                <p className="subtitle">in 2020 halving</p>
              </div>

              {/* Vertical Separator */}
              <VerticalSeperator style={{ opacity: 0.3 }} width="0.05rem" height="1.5rem" />

              <div className="right">
                <ReactECharts
                  option={{
                    series: [
                      {
                        type: "pie",
                        radius: ["70%", "100%"], // Create a circular progress ring
                        startAngle: 90, // Rotate starting point
                        avoidLabelOverlap: false,
                        label: { show: false },
                        labelLine: { show: false },
                        data: [
                          {
                            value: daysSinceHalving, // Green part (progress)
                            itemStyle: { color: "#A6FF55" }, // Green progress
                          },
                          {
                            value: daysToPeak - daysSinceHalving, // Remaining days
                            itemStyle: { color: "rgb(45, 26, 62)" }, // Background arc color
                          },
                        ],
                      },
                    ],
                    graphic: [
                      {
                        type: "text",
                        left: "center",
                        top: "center",
                        style: {
                          text: `${daysLeft}`, // Days left to peak
                          fill: "#FFFFFF", // Text color
                          fontSize: 14, // Font size
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif", // Ensure font matches page
                        },
                      },
                    ],
                  }}
                  style={{ width: "2.5rem", height: "2.5rem" }} // Adjust size as needed
                />
                <span className="chart-text">days to<br /> peak</span> {/* Add centered text below */}

              </div>
            </div>

            {/* Vertical Separator */}
            <HorizontalSeperator style={{ opacity: 0.3 }} width="97%" height="0.02rem" />

            {/* Right Section */}
            {/* Right Section */}
            <div className="middle-table">
              <div className="row">
                <span className="title"></span> {/* Placeholder for alignment */}
                <span className="title">{daysSinceHalving} days after</span>
                <span className="title">Peak</span>
              </div>
              <div className="row">
                <span className="title">Price</span>
                <span className="text">{formatPrice(price209DaysAfterTopCoin)}</span>
                <span className="text">{formatPrice(peakPriceTopCoin)}</span>
              </div>
              <div className="row">
                <span className="title">M.Cap</span>
                <span className="text">${formatMarketCap(marketCap209DaysAfterTopCoin)}</span>
                <span className="text">${formatMarketCap(peakMarketCapTopCoin)}</span>
              </div>
            </div>

          </div>

        </MobileTable>






        {/* Graph Section */}
        <Column gap="0.2rem" width="100%">
          <SmallText style={{ marginTop: "1rem" }} align={"center"} size={"1rem"}>
            The graphic below shows how {coin2.coinName} performed throughout the last halving:
          </SmallText>
          <HalvingGraph key={new Date().getTime()} coin={coin2} />
        </Column>
      </div>
    </HalvingContext.Provider>
  );
}

function HalvingBoxLanding({
  halvingCoins,
  halvingCoinsStatus,
  handleCompare,
  setCoin1,
  setCoin2,
  externalCoin,
}) {

  const [mode, setMode] = useState("crypto");


  return halvingCoinsStatus === "success" ? (
    <React.Fragment>
      <HideOnMobileWrapper>
        <Header>Compare your coin</Header>
      </HideOnMobileWrapper>
      <ShowOnMobileWrapper>
        <LightHeader>Compare your coin</LightHeader>
      </ShowOnMobileWrapper>
      <StyledHalvingBox>
        <HalvingBoxDropdownWrapper gap="1rem" width="18rem" align="start">
          <SmallText>Your coin</SmallText>
          <Dropdown
            noPromotion={true}
            setCoin={setCoin1}
            defaultCoin={defaultCoins.ethereum}
            mode={mode}
            setMode={setMode}
          />
        </HalvingBoxDropdownWrapper>
        <HideOnMobileWrapper style={{ alignSelf: "center" }}>
          <Header>vs</Header>
        </HideOnMobileWrapper>
        <HalvingBoxDropdownWrapper gap="1rem" width="18rem" align="start">
          <SmallText>Compare to</SmallText>
          <Column width="100%" gap="0.5rem">
            <Dropdown
              noPromotion={true}
              setCoin={setCoin2}
              items={halvingCoins}
              defaultCoin={defaultCoins.bitcoin}
              mode={mode}
              setMode={setMode}
            />
            <SmallText color={({ theme }) => theme.colors.LIGHT_TEXT}>
              More coins coming soon
            </SmallText>
          </Column>
        </HalvingBoxDropdownWrapper>
        <HalvingBoxDropdownWrapper isFake="true">
          <HideOnMobileWrapper>
            <SmallText style={{ visibility: "hidden" }}>.</SmallText>
          </HideOnMobileWrapper>
          <OutlineButton
            borderWidth={borderWidths.SMALL}
            borderColor={({ theme }) => theme.colors.HALVING}
            hoverColor={({ theme }) => theme.colors.HALVING}
            onClick={() => handleCompare()}
          >
            GO
          </OutlineButton>
        </HalvingBoxDropdownWrapper>
      </StyledHalvingBox>
    </React.Fragment>
  ) : (
    <Loader />
  );
}
