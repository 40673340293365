import { Helmet } from "react-helmet";
import { Column, FullRow, Row } from "../components/containers/flexbox.styled";
import {
  Container,
  VerticalSeperator,
} from "../components/containers/boxes.styled";
import {
  BigText,
  Header,
  Paragraph,
  SmallText,
} from "../components/texts.styled";
import { RouteLink } from "../routing/routeLink";
import { AllCoinsContext, CoinsContext } from "../context";
import { useContext, useEffect, useState, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getCompareCoins, getHolderCompareList } from "../components/stream";
import { formatNumber } from "../util/numberFormatter";
import MostCompared from "../components/misc/mostCompared/mostCompared";
import { default as CompareSection } from "../components/compareSection/compare";
import CompareResult, {
  StyledCompareResult,
} from "../components/comparePage/compareResult";
import styled from "styled-components";
import { theme } from "../components/theme.styled";
import { borderRads, textSizes } from "../components/sizes";
import { AmountInputStyled } from "../components/compareSection/amountInput.styled";
import Switch from "../components/misc/switch";
import { StyledCompare } from "../components/compareSection/compare.styled";
import CompareAgainMobile from "../components/comparePage/compareAgainMobile";
import { AreaChartWrapper } from "../components/dedicatedCoin/simpleChart";
import BarChartWrapper from "../components/dedicatedCoin/simpleChartBar";
import {
  StyledDedicatedCoinBottomBar,
  StyledDedicatedCoinBottomBarPage,
} from "./dedicatedCoinNew";
import { StyledHolderSizeTableDesktop } from "../components/holderCompare/holderCompareMobile";
import { RiTwitterXLine, RiWhatsappLine } from "react-icons/ri";
import { MdContentCopy } from "react-icons/md";
import { Icon } from "../components/icon.styled";
import CopyButton from "../components/misc/copyButton";
import { TwitterShareButton, WhatsappShareButton } from "react-share";
import { StocksContext } from "../context";

export default function Compare() {
  let { coin1, coin2 } = useParams();

  const location = useParams();

  const allItems = useContext(AllCoinsContext);

  let [searchParams, setSearchParams] = useSearchParams();

  const [amountInput, setAmount] = useState(null);
  const [amountType, setAmountType] = useState(null);

  const [coin1data, setCoin1data] = useState(null);
  const [coin2data, setCoin2data] = useState(null);

  const [noCoins, setNoCoins] = useState(false);

  const stocks = useContext(StocksContext);

  // Add after other state declarations
  const isStock = useMemo(() =>
    coin2data?.id?.endsWith('-stock'),
    [coin2data]
  );

  const [isStock1, isStock2] = useMemo(() => [
    coin1data?.id?.endsWith('-stock'),
    coin2data?.id?.endsWith('-stock')
  ], [coin1data, coin2data]);

  const {
    data,
    status,
    refetch: fetch,
  } = useQuery(
    ["compare", coin1, coin2],
    () => getCompareCoins(coin1, coin2, true),
    {
      enabled: false,
      staleTime: Infinity,
      onSuccess: (data) => {
        console.log("YOOO");
        setCoin1data(data.coin1data);
        if (searchParams.get("amount") !== null) {
          setAmount(searchParams.get("amount"));
          setAmountType("amount");
        } else if (searchParams.get("$") !== null) {
          setAmount(searchParams.get("$"));
          setAmountType("$");
        }
        setCoin2data(data.coin2data);
      },
    }
  );

  useEffect(() => {
    return () => {
      console.log("unmounting");
      setCoin1data(null);
      setCoin2data(null);
    };
  }, []);

  useEffect(() => {
    if (coin1 === undefined || coin2 === undefined || coin1 === null || coin2 === null) {
      window.location.replace("/compare/ethereum/bitcoin");
    } else {
      const isStock1 = coin1.endsWith('-stock');
      const isStock2 = coin2.endsWith('-stock');

      // Check if coin1 is valid (either in allItems if crypto, or in stocks if stock)
      const isValidCoin1 = isStock1
        ? stocks.map(item => item.id).includes(coin1)
        : allItems.map(item => item.id).includes(coin1);

      // Check if coin2 is valid (either in allItems if crypto, or in stocks if stock)
      const isValidCoin2 = isStock2
        ? stocks.map(item => item.id).includes(coin2)
        : allItems.map(item => item.id).includes(coin2);

      if (!isValidCoin1 || !isValidCoin2) {
        setNoCoins(true);
      } else {
        fetch();
      }
    }
  }, [location]);

  /*   useEffect(() => {
    if (
      coin1 === undefined ||
      coin2 === undefined ||
      coin1 === null ||
      coin2 === null
    ) {
      window.location.replace("/compare/ethereum/bitcoin");
    } else if (
      !allItems.map((item) => item.id).includes(coin1) ||
      !allItems.map((item) => item.id).includes(coin2)
    ) {
      setNoCoins(true);
    } else {
      fetch();
    }
  }, []); */

  const [athTimes, setAthTimes] = useState(null);

  const [activePage, setActivePage] = useState(0);

  const {
    data: holderList,
    status: holderListStatus,
    refetch: fetchHolderList,
  } = useQuery(["getHolderCoinsList"], () => getHolderCompareList(), {
    staleTime: Infinity,
  });

  const [athMode, setAthMode] = useState("current");

  return (
    <Container>
      {coin1data !== null && coin2data !== null && (
        <Helmet>
          <title>
            {`${coin1data.companyName || coin1data.coinName} Price With Market Cap Of ${coin2data.companyName || coin2data.coinName
              } (${coin1data.symbol.toUpperCase()} vs ${coin2data.symbol.toUpperCase()
              })`}
          </title>

          <meta
            name="description"
            content={`Price of ${coin1data.companyName || coin1data.coinName
              }(${coin1data.symbol.toUpperCase()}) with the market cap of ${coin2data.companyName || coin2data.coinName
              }(${coin2data.symbol}) is $${formatNumber(
                coin1data.price * ((coin2data.market_cap || coin2data.marketcap) / coin1data.marketcap)
              )}(${formatNumber(
                (coin1data.price * ((coin2data.market_cap || coin2data.marketcap) / coin1data.marketcap)) /
                coin1data.price
              )}x)`}
          />
        </Helmet>
      )}

      {noCoins && (
        <Column style={{ margin: "3rem auto", textAlign: "center", gap: "2rem" }}>
          <Header>
            One of the assets could not be found. Please select them from dropdowns.
          </Header>
          <RouteLink to={"/"}>
            <Paragraph>Go to Home Page</Paragraph>
          </RouteLink>
        </Column>
      )}

      <StyledDedicatedCoinBottomBar>
        <FullRow justify="space-between">
          <StyledDedicatedCoinBottomBarPage
            isActive={activePage === 0}
            onClick={() => {
              setActivePage(0);
            }}
          >
            COMPARE
          </StyledDedicatedCoinBottomBarPage>
          <StyledDedicatedCoinBottomBarPage
            isActive={activePage === 1}
            onClick={() => {
              setActivePage(1);
            }}
          >
            CHARTS
          </StyledDedicatedCoinBottomBarPage>
        </FullRow>
      </StyledDedicatedCoinBottomBar>

      <MostCompared />

      {status === "success" && coin1data && coin2data && (
        <CoinsContext.Provider
          value={{ coin1data, coin2data, amountInput, amountType }}
        >
          {activePage === 0 && (
            <CompareNewTopRow>
              <CompareSection
                key={coin1data.id + coin2data.id + "dropdown"}
                external={true}
                defaultCoin1={coin1data}
                defaultCoin2={coin2data}
              />
              <CompareAgainMobile />
              <CompareResult  key={`${coin1data?.id}-${coin2data?.id}`} setMode={setAthMode} setAthTimes={setAthTimes} />
              <Column className="profit-calculator-container">
                <Row
                  className="get-listed"
                  style={{
                    borderRadius: borderRads.SMALL,
                    width: "100%",
                    height: "100%",
                    maxHeight: "5rem",
                  }}
                  justify="center"
                >
                  <StyledHolderSizeTableDesktop style={{ height: "100%" }}>
                    <div
                      className="section"
                      style={{ paddingLeft: "1.5rem", paddingRight: "1.5rem" }}
                    >
                      <SmallText>Share</SmallText>
                    </div>
                    <div className="section">
                      <TwitterShareButton
                        style={{ display: "flex", alignItems: "center" }}
                        title={`If ${coin1data.symbol.toUpperCase()} reaches ${isStock
                          ? `${coin2data.companyName} (${coin2data.symbol})`
                          : coin2data.symbol.toUpperCase()
                          }'s ${athMode === "current" ? "current" : "ATH"} market cap, its price would be $${formatNumber(
                            coin1data.price * ((coin2data.market_cap || coin2data.marketcap) / coin1data.marketcap)
                          )}. That makes ${formatNumber(
                            (coin2data.market_cap || coin2data.marketcap) / coin1data.marketcap
                          )}x!`}
                        url={`comparemarketcap.com/compare/${coin1data.id}/${coin2data.id}`}
                        hashtags={[coin1data.coinName, coin2data.symbol]}
                      >
                        <Icon size="1.5rem">
                          <RiTwitterXLine />
                        </Icon>
                      </TwitterShareButton>
                    </div>
                    <VerticalSeperator
                      color={"rgba(255,255,255,0.1)"}
                      height={"70%"}
                    />
                    <div className="section">
                      <WhatsappShareButton
                        style={{ display: "flex", alignItems: "center" }}
                        title={`If ${coin1data.symbol.toUpperCase()} reaches ${coin2data.symbol.toUpperCase()}'s current market cap, its price would be $${formatNumber(
                          coin1data.price *
                          (coin2data.marketcap / coin1data.marketcap)
                        )}. That makes ${formatNumber(
                          (coin1data.price *
                            (coin2data.marketcap / coin1data.marketcap)) /
                          coin1data.price
                        )}x!
                        `}
                        url={`comparemarketcap.com/compare/${coin1data.id}/${coin2data.id}`}
                      >
                        <Icon size="1.5rem">
                          <RiWhatsappLine />
                        </Icon>
                      </WhatsappShareButton>
                    </div>
                    <VerticalSeperator
                      color={"rgba(255,255,255,0.1)"}
                      height={"70%"}
                    />

                    <div className="section">
                      <CopyButton
                        size={"1.5rem"}
                        text={`comparemarketcap.com/compare/${coin1data.id}/${coin2data.id}`}
                      />
                    </div>
                  </StyledHolderSizeTableDesktop>
                </Row>
                <ProfitCalculator
                  coin1data={coin1data}
                  coin2data={coin2data}
                  athTimes={athTimes}
                  athMode={athMode}
                  amountType={amountType}
                  amountInput={amountInput}
                  key={coin1data.id + coin2data.id + amountType + amountInput}
                />
              </Column>
            </CompareNewTopRow>
          )}

          {activePage === 0 ? (
            holderList &&
              holderList.filter((item) => item.id === coin1).length > 0 &&
              holderList.filter((item) => item.id === coin2).length > 0 ? (
              <FullRow
                className="compare-charts"
                gap="1.5rem"
                align="start"
                style={{ margin: "1rem auto", maxWidth: "60rem" }}
              >
                <Column
                  width="100%"
                  align="center"
                  gap="1rem"
                  style={{
                    height: "100%",
                    padding: "0.5rem",
                    borderRadius: borderRads.SMALL,
                    backgroundColor: theme.colors.LEVEL,
                  }}
                >
                  <SmallText>Price Charts</SmallText>
                  {!isStock1 && (
                  <AreaChartWrapper
                    coinId={coin1}
                    coin={coin1data}
                    key={coin1data.id + "price"}
                    isCompareDesktop={true}
                  />
                  )}
                  {!isStock2 && (
                  <AreaChartWrapper
                    coinId={coin2}
                    coin={coin2data}
                    key={coin2data.id + "price"}
                    isCompareDesktop={true}
                  />
                  )}
                </Column>
                <Column
                  width="100%"
                  align="center"
                  gap="1rem"
                  style={{
                    padding: "0.5rem",
                    borderRadius: borderRads.SMALL,
                    backgroundColor: theme.colors.LEVEL,
                  }}
                >
                  <SmallText>Holder Charts</SmallText>
                  {!isStock1 && (
                  <BarChartWrapper
                    coinId={coin1}
                    coin={coin1data}
                    key={coin1data.id + "holder"}
                    isCompareDesktop={true}
                  />
                  )}
                  {!isStock2 && (
                  <BarChartWrapper
                    coinId={coin2}
                    coin={coin2data}
                    key={coin2data.id + "holder"}
                    isCompareDesktop={true}
                  />
                  )}
                </Column>
              </FullRow>
            ) : (
              <Column
                className="compare-charts"
                width="100%"
                align="center"
                gap="1rem"
                style={{
                  padding: "0.5rem",
                  margin: "0 auto",
                  marginTop: "1rem",
                  borderRadius: borderRads.SMALL,
                  backgroundColor: theme.colors.LEVEL,
                  maxWidth: "60rem",
                }}
              >
                <SmallText>Price Charts</SmallText>
                <FullRow gap="1rem">
                  <div style={{ width: "100%" }}>
                  {!isStock1 && (
                    <AreaChartWrapper
                      coinId={coin1}
                      coin={coin1data}
                      key={coin1data.id + "price2"}
                    />
                  )}
                  </div>
                  <div style={{ width: "100%" }}>
                  {!isStock2 && (
                    <AreaChartWrapper
                      coinId={coin2}
                      coin={coin2data}
                      key={coin2data.id + "price2"}
                    />
                  )}
                  </div>
                </FullRow>
              </Column>
            )
          ) : (
            <>
              <Column
                width="100%"
                align="center"
                gap="1rem"
                style={{
                  padding: "0.5rem",
                  borderRadius: borderRads.SMALL,
                  backgroundColor: theme.colors.LEVEL,
                }}
              >
                <SmallText>Price Charts</SmallText>
                {!isStock1 && (
                <AreaChartWrapper
                  coinId={coin1data.id}
                  coin={coin1data}
                  key={coin1data.id + "price3"}
                  isCompareMobile={true}
                />
                )}
                {!isStock2 && (
                  <AreaChartWrapper
                    coinId={coin2data.id}
                    coin={coin2data}
                    key={coin2data.id + "price3"}
                    isCompareMobile={true}
                  />
                )}
              </Column>
              
                <Column
                  width="100%"
                  align="center"
                  gap="1rem"
                  style={{
                    padding: "0.5rem",
                    borderRadius: borderRads.SMALL,
                    backgroundColor: theme.colors.LEVEL,
                  }}
                >
                  <SmallText>Holder Charts</SmallText>
                  {!isStock1 && (
                  <BarChartWrapper
                    coinId={coin1data.id}
                    coin={coin1data}
                    key={coin1data.id + "holder2"}
                    isCompareMobile={true}
                  />
                  )}
                  {!isStock2 && (
                  <BarChartWrapper
                    coinId={coin2data.id}
                    coin={coin2data}
                    key={coin2data.id + "holder2"}
                    isCompareMobile={true}
                  />
                )}
                </Column>
            </>
          )}
        </CoinsContext.Provider>
      )}
    </Container>
  );
}

const CompareNewTopRow = styled(FullRow)`
  gap: 1.5rem;
  width: 100%;
  justify-content: space-between;
  align-items: start;

  .profit-calculator-container {
    width: 23rem;
    max-width: 23rem;
    min-width: 23rem;
    gap: 1rem;
    height: 100%;
  }

  @media (max-width: 90rem) {
    .profit-calculator-container {
      min-width: 21rem !important;
      width: auto !important;
    }

    ${StyledCompare} {
      min-width: 21rem !important;
      width: auto !important;
    }
  }
  @media (max-width: 80rem) {
    .profit-calculator-container {
      min-width: 18rem !important;
      width: auto !important;
    }

    ${StyledCompare} {
      min-width: 18rem !important;
      width: auto !important;
    }
  }

  @media (max-width: 67rem) {
    flex-wrap: wrap;

    ${StyledCompareResult} {
      width: fit-content;
      max-width: 40rem;
      width: 60%;
    }

    & > * {
      width: fit-content;
    }
    & > *:last-child {
      flex-direction: row;
      width: 100% !important;
      height: 11rem;
      max-width: none;
    }
  }

  @media (max-width: 62rem) {
    & > * {
      width: 100% !important;
      max-width: none !important;
    }
    flex-wrap: nowrap;
    //dont show first child
    gap: 1rem;

    & > *:first-child {
      display: none;
    }

    flex-direction: column;

    ${StyledCompareResult} {
      overflow: visible !important;
      min-width: auto !important;
      border-radius: ${borderRads.SMALL};
      padding-top: 0.5rem;

      //hide first child
      & > *:first-child {
        display: none;
      }
    }

    .get-listed {
      display: none;
    }

    .profit-calculator-container {
      width: 100% !important;
      max-width: none;
    }
  }

  @media (max-width: 50rem) {
    & > *:last-child {
      flex-direction: column;
    }
  }
`;

export function ProfitCalculator({
  athTimes,
  athMode,
  amountInput,
  amountType,
}) {
  const { coin1data, coin2data } = useContext(CoinsContext);

  const [mode, setMode] = useState("$");

  const [amount, setAmount] = useState(0);

  let [searchParams, setSearchParams] = useSearchParams();

  let isAmount;
  if (searchParams.get("amount") !== null) {
    isAmount = true;
  } else if (searchParams.get("$") !== null) {
    isAmount = false;
  }

  useEffect(() => {
    if (amountInput !== undefined && !isNaN(amountInput)) {
      setAmount(amountInput);
    }
  }, [amountInput]);

  useEffect(() => {
    if (amountType !== undefined) {
      setMode(amountType);
    }
  }, [amountType]);

  const futurePrice =
    coin1data.price * (coin2data.marketcap / coin1data.marketcap);

  const switchElements = [
    {
      name: coin1data.symbol.toUpperCase(),
      onClick: () => setMode("amount"),
      image: coin1data.image,
    },
    {
      name: "$",
      onClick: () => setMode("$"),
    },
  ];

  return (
    <StyledProfitCalculator>
      <SmallText>Wonder your profit?</SmallText>
      <Column height="4rem" justify="center" align="start" width="100%">
        {amount ? (
          <Row
            className="vertical-on-desktop"
            align="center"
            justify="center"
            width="100%"
            gap="0rem"
          >
            {athMode !== "ath" && (
              <SmallText
                align={"center"}
                weight={textSizes.fontWeights.REGULAR}
                width="100%"
              >
                <span
                  style={{
                    color: theme.colors.LIGHT_TEXT,
                  }}
                >
                  Your{" "}
                  {futurePrice - coin1data.price > 0
                    ? "profit (current)"
                    : "loss (current)"}
                  :
                </span>
                <br />
                <span className="bigger">
                  $
                  {formatNumber(
                    (futurePrice - coin1data.price) *
                    (mode === "amount" ? amount : amount / coin1data.price),
                    false
                  )
                    .toString()
                    .replace("-", "")}
                </span>
                <span
                  className="bigger"
                  style={{
                    color:
                      futurePrice - coin1data.price >= 0
                        ? theme.colors.GREEN
                        : theme.colors.RED,
                  }}
                >
                  {" "}
                  ({formatNumber(futurePrice / coin1data.price, false)}
                  x)
                </span>
              </SmallText>
            )}

            {athMode === "ath" && athTimes && (
              <SmallText
                align={"center"}
                weight={textSizes.fontWeights.REGULAR}
                width="100%"
              >
                <span
                  style={{
                    color: theme.colors.LIGHT_TEXT,
                  }}
                >
                  Your{" "}
                  {futurePrice - coin1data.price > 0
                    ? "profit (ATH)"
                    : "loss (ATH)"}
                  :
                </span>
                <br />
                <span className="bigger">
                  $
                  {formatNumber(
                    (athTimes * coin1data.price - coin1data.price) *
                    (mode === "amount" ? amount : amount / coin1data.price),
                    false
                  )
                    .toString()
                    .replace("-", "")}
                </span>
                <span
                  className="bigger"
                  style={{
                    color:
                      athTimes >= 1 ? theme.colors.GREEN : theme.colors.RED,
                  }}
                >
                  {" "}
                  ({formatNumber(athTimes, false)}
                  x)
                </span>
              </SmallText>
            )}
          </Row>
        ) : (
          <SmallText
            style={{
              color: theme.colors.LIGHT_TEXT,
              //make it italic
              fontStyle: "italic",
              margin: "0 auto",
            }}
          >
            Enter your amount
          </SmallText>
        )}
      </Column>
      <AmountInputStyled>
        <input
          maxLength={15}
          value={amount ? formatNumber(amount) : ""}
          onChange={(e) => {
            let value = e.target.value;
            //if the value is not a number, return
            if (isNaN(value.slice(-1))) return;

            //remove any commas
            value = value.replace(/,/g, "");
            setAmount(Number(value) < 0 ? -Number(value) : value);
          }}
          placeholder={`Enter amount in ${mode === "amount" ? coin1data.symbol.toUpperCase() : "$"
            }`}
        />
        <Switch selectedIndexs={isAmount ? 0 : 1} options={switchElements} />
      </AmountInputStyled>
    </StyledProfitCalculator>
  );
}

const StyledProfitCalculator = styled(Column)`
  padding: 1rem;
  border-radius: ${borderRads.SMALL};
  width: 100%;
  gap: 0.75rem;
  background-color: ${theme.colors.LEVEL};
  height: 100%;
  justify-content: space-between;

  .bigger {
    font-size: 1.3rem !important;
  }

  ${Row} {
    ${SmallText} {
      line-height: 1.6rem;
    }
  }

  @media (max-width: 50rem) {
    ${Row} {
      ${SmallText} {
        font-size: 0.9rem !important;
        line-height: 1.6rem;

        .bigger {
          font-size: 1.2rem !important;
        }
      }
    }
  }
`;
