import React from "react";
import { FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { MdMailOutline } from "react-icons/md";
import { TransparentButton } from "./buttons/buttons.styled";
import { HorizontalSeperator } from "./containers/boxes.styled";
import { Column, Row } from "./containers/flexbox.styled";
import {
  FooterSection,
  StyledFooter,
  StyledFooterSections,
} from "./footer.styled";
import { Icon } from "./icon.styled";
import { LightHeader, Paragraph, SmallText } from "./texts.styled";
import { RouteLink } from "../routing/routeLink";

export default function Footer() {
  return (
    <StyledFooter>
      <StyledFooterSections>
        <FooterSection>
          <Column align={"start"} gap={"0.3rem"}>
            <LightHeader>Links</LightHeader>
            <HorizontalSeperator width={"3rem"} />
          </Column>

          <RouteLink to={"/dreamfolio"}>
            <Paragraph>Dreamfolio</Paragraph>
          </RouteLink>
          <RouteLink to={"/halving"}>
            <Paragraph>Halving Report</Paragraph>
          </RouteLink>
          {/* <RouteLink to={"/terminal/bitcoin"}>
            <Paragraph>Terminal</Paragraph>
          </RouteLink> */}
          <RouteLink to={"/promote"}>
            <Paragraph>Promote</Paragraph>
          </RouteLink>
        </FooterSection>
        <FooterSection>
          <Column align={"start"} gap={"0.3rem"}>
            <LightHeader>Contact</LightHeader>
            <HorizontalSeperator width={"3rem"} />
          </Column>
          <a href={"mailto:contact@comparemarketcap.com"}>
            <Row gap={"0.4rem"}>
              <Icon size="1.2rem">
                <MdMailOutline />
              </Icon>
              <Paragraph>contact@comparemarketcap.com</Paragraph>
            </Row>
          </a>
        </FooterSection>
        <FooterSection>
          <Column align={"start"} gap={"0.3rem"}>
            <LightHeader>social</LightHeader>
            <HorizontalSeperator width={"3rem"} />
          </Column>
          <Row gap={"1.5rem"}>
            <a
              aria-label="Go to X."
              href={`https://x.com/comparemcap`}
              target={"_blank"}
            >
              <TransparentButton aria-label="x-button">
                <Icon>
                  <svg
                    version="1.1"
                    id="svg5"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 1668.56 1221.19"
                    xmlSpace="preserve"
                  >
                    <g id="layer1" transform="translate(52.390088,-25.058597)">
                      <path
                        id="path1009"
                        d="M283.94,167.31l386.39,516.64L281.5,1104h87.51l340.42-367.76L984.48,1104h297.8L874.15,558.3l361.92-390.99
                                                h-87.51l-313.51,338.7l-253.31-338.7H283.94z M412.63,231.77h136.81l604.13,807.76h-136.81L412.63,231.77z"
                      />
                    </g>
                  </svg>
                </Icon>
              </TransparentButton>
            </a>
            <a
              aria-label="Go to Instagram."
              href={`https://www.instagram.com/comparemarketcap/`}
              target={"_blank"}
            >
              <TransparentButton aria-label="instagram-button">
                <Icon>
                  <FaInstagram />
                </Icon>
              </TransparentButton>
            </a>
            <a
              aria-label="Go to LinkedIn."
              href={`https://www.linkedin.com/company/comparemarketcap`}
              target={"_blank"}
            >
              <TransparentButton aria-label="linkedin-button">
                <Icon>
                  <FaLinkedin />
                </Icon>
              </TransparentButton>
            </a>
            <a
              aria-label="Go to Youtube."
              href={`https://www.youtube.com/@CompareMarketCap`}
              target={"_blank"}
            >
              <TransparentButton aria-label="youtube-button">
                <Icon>
                  <FaYoutube />
                </Icon>
              </TransparentButton>
            </a>
          </Row>
        </FooterSection>
      </StyledFooterSections>

      <SmallText>Always do your own research. Not investment advice.</SmallText>
    </StyledFooter>
  );
}
