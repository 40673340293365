import styled from "styled-components";
import { borderRads, borderWidths, textSizes } from "../sizes";
import { Column, Row } from "../containers/flexbox.styled";
import { paddings } from "../paddings.styled";
import Dropdown from "../compareSection/dropdown";
import { StyledDropdown } from "../compareSection/dropdown.styled";
import {
  OutlineButton,
  PrimaryButton,
  TransparentButton,
} from "../buttons/buttons.styled";
import { BigText, Header, Paragraph, SmallText } from "../texts.styled";
import { Icon } from "../icon.styled";
import {
  HorizontalSeperator,
  VerticalSeperator,
} from "../containers/boxes.styled";
import { Footer } from "react-day-picker";

export const StyledHalvingBoxWrapper = styled(Column)`
  background-color: ${({ theme }) => theme.colors.PRIMARY};
  max-width: 55rem;
  transition: 0.5s;
  width: 80%;
  margin: 0 auto;
  height: fit-content;
  @media (max-width: 63rem) {
    /* height: ${({ pressed }) => (pressed ? "68rem" : "24rem")}; */
  }
  @media (max-width: 40rem) {
    margin-top: -2rem;
    border-radius: 0px;
    width: 100%;
    gap: 0.5rem;
    /* height: ${({ pressed }) => (pressed ? "68rem" : "22rem")}; */
    padding: ${({ pressed }) =>
      pressed ? paddings.LOW : `${paddings.MID} ${paddings.HIGH}`};
  }

  ${Paragraph} {
    font-size: 1.2rem !important;
    @media (max-width: 50rem) {
      font-size: ${textSizes.fontSizes.SMALL} !important;
    }
  }

  ${SmallText} {
    font-size: ${textSizes.fontSizes.SMALL} !important;
    font-weight: ${textSizes.fontWeights.LIGHT} !important;
    @media (max-width: 50rem) {
      font-size: ${textSizes.fontSizes.SMALL} !important;
    }
  }
  margin-top: -3rem;
  z-index: 1;
  /* height: ${({ pressed }) => (pressed ? "56rem" : "14rem")}; */
  border: ${borderWidths.SMALL} solid ${({ theme }) => theme.colors.LEVEL2};
  border-radius: ${borderRads.MEDIUM};
  padding: 1rem 2rem;
  gap: 1.5rem;
  filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.3));
`;

export const StyledHalvingBox = styled(Row)`
  width: 100%;
  justify-content: space-between;
  gap: 2rem;
  align-items: start;

  @media (max-width: 70rem) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;

export const HalvingBoxDropdownWrapper = styled(Column)`
  align-items: start;
  gap: 1rem;
  width: ${({ isFake }) => (isFake ? "fit-content" : "100%")};
  &:first-child {
    ${StyledDropdown} {
      border: ${borderWidths.SMALL} solid ${({ theme }) => theme.colors.HALVING};
    }
  }
  &:nth-child(3) {
    ${StyledDropdown} {
      border: ${borderWidths.SMALL} solid ${({ theme }) => theme.colors.LEVEL2};
    }
  }

  ${OutlineButton} {
    height: 4.2rem !important;
    width: 6rem;
    @media (max-width: 70rem) {
      margin-top: 0.5rem;
      width: 100% !important;
      height: auto !important;
    }
  }

  @media (max-width: 70rem) {
    width: 100% !important;
    gap: 0.3rem;
  }
`;
 
export const StyledHalvingResultThree = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: 0.5rem;

  ${VerticalSeperator} {
    display: block !important;
  }
  ${HorizontalSeperator} {
    display: none !important;
  }

  @media (max-width: 70rem) {
    ${HorizontalSeperator} {
      display: none !important;
    }
    ${VerticalSeperator} {
      display: none !important;
    }
  }
  @media (max-width: 50rem) {
    gap: 1rem;
  }
  @media (max-width: 45rem) {
    ${HorizontalSeperator} {
      display: block !important;
    }
    ${VerticalSeperator} {
      display: none !important;
    }
  }

  .endOfCycle {
    @media (min-width: 70rem) {
      position: absolute;
      top: -1rem;
    }
    white-space: nowrap;
  }
`;

export const StyledHalvingResultColumn = styled(Column)`
  gap: 1rem;
  width: 33%;
  min-width: 15rem;
  @media (max-width: 45rem) {
    gap: 0.5rem;
  }
  ${BigText} {
    font-weight: ${textSizes.fontWeights.LIGHT};
    &:first-child {
    }
    &:nth-child(2) {
      color: ${({ theme }) => theme.colors.GREEN};
      font-size: ${textSizes.fontSizes.HEADER};
    }
    font-size: ${textSizes.fontSizes.BIG};
    @media (max-width: 50rem) {
      font-size: ${textSizes.fontSizes.HEADER};
    }
  }

  & > *:first-child {
    @media (max-width: 45rem) {
      .date {
        @media (max-width: 45rem) {
          flex-direction: row;
          gap: 0.5rem;
          ${Header} {
            font-size: ${textSizes.fontSizes.MEDIUM};
          }
          ${Icon} {
            font-size: ${textSizes.fontSizes.HEADER};
          }
        }
      }
    }
  }

  & > *:nth-child(2) {
    @media (max-width: 45rem) {
      flex-direction: row;
      gap: 0.5rem;
    }
  }
`;

export const StyledHalvingBoxBackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  gap: 0;
  @media (max-width: 45rem) {
    top: 0.3rem;
    left: 0.3rem;
    & > *:nth-child(2) {
      display: none;
    }
  }
`;

// Styled component for the rectangles
export const InfoRectangle = styled.div`
  border-radius: ${borderRads.MEDIUM};
  border: ${borderWidths.SMALL} solid ${({ theme }) => theme.colors.LEVEL2};
  background-color: ${({ theme }) => theme.colors.PRIMARY};
  width: 100%; /* Responsive width */
  height: auto; /* Increased height */
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  flex-direction: column; /* Stack content vertically */
  justify-content: flex-start; /* Space out content evenly */
  align-items: center; /* Center content horizontally */
  padding: 0.4rem; /* Add padding for better spacing */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Added slight shadow */
  box-sizing: border-box;

  @media (max-width:520px){
    padding: 1rem;
  }
`;

export const InfoTopRectangle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.LEVEL};
  padding: 1rem 2rem;
  border-radius: 0px 0px 50px 50px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  gap: 1rem;
  width: 60%;
  margin-bottom: 1rem; /* Space below */
  margin-top: -0.4rem;

   /* Responsive Adjustments */
  @media (max-width: 850px) {
    text-align: center;
    width: auto;
    min-width: 60%;
    padding: 1rem 2rem; /* Reduced padding */
    gap: 0.5rem; /* Reduce gaps */
  }

  

  /* Inside Elements */
  div {
    flex: 1;
    text-align: center;
    white-space: nowrap;

    /* Styling for Title and Value */
    h4 {
      font-size: 0.8rem;
      color: ${({ theme }) => theme.colors.LIGHT_TEXT};
      margin-bottom: 0.5rem;
    }
    p {
      font-size: 1.2rem;
      color: ${({ theme }) => theme.colors.WHITE};
    }

    img {
      width: 3rem;
      height: 3rem;
      margin: 0 auto; /* Center the logo */
    }
  }
  @media (max-width: 700px) {
  text-align: center;
  padding: 1rem; /* Reduced padding */
  gap: 0.7rem; /* Reduce gaps */
  
  h4 {
      font-size: 0.7rem !important; /* Smaller size */
    }
    p {
      font-size: 1.05rem !important;
    }
}
 @media (max-width: 520px) {
  display: none;
  text-align: center;
  padding: 1.2rem; /* Reduced padding */
  gap: 0.7rem; /* Reduce gaps */

  h4 {
      font-size: 0.63rem !important; /* Smaller size */
    }
    p {
      font-size: 0.92rem !important;
    }
    img{
    width: 2.5rem !important; 
    height: 2.5rem !important;
  }
}
`;

export const MobileTopRectangle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.LEVEL};
  padding: 1rem 2rem;
  border-radius: ${borderRads.MEDIUM};
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  gap: 0rem;
  width: 100%;
  margin-top: -1.8rem;
  margin-bottom: -1.7rem;
   /* Responsive Adjustments */
  @media (min-width: 521px) {
    display: none;
    }

  

  /* Inside Elements */
  div {
    flex: 1;
    text-align: center;
    white-space: nowrap;

    /* Styling for Title and Value */
    h4 {
      font-size: 0.8rem;
      color: ${({ theme }) => theme.colors.LIGHT_TEXT};
      margin-bottom: 0.5rem;
    }
    p {
      font-size: 1.2rem;
      color: ${({ theme }) => theme.colors.WHITE};
    }

    img {
      width: 3rem;
      height: 3rem;
      margin: 0 auto; /* Center the logo */
    }
  }
`;

export const InfoBottomRectangle = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  white-space: nowrap;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.LEVEL};
  border-radius: ${borderRads.MEDIUM};
  width: auto;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  margin-bottom: 0.5rem; /* Space below */
  padding: 0.3rem 3rem;
  
 /* Responsive Adjustments */
  @media (max-width: 700px) {
    flex-direction: column;
    text-align: center;
  }

  div {
    flex: 1;
    text-align: center;

    #price {
      font-size: 2rem;
      color: ${({ theme }) => theme.colors.WHITE};
      margin-bottom: 0.3rem;
    }
    #mcap {
      font-size: 1.1rem;
      color: ${({ theme }) => theme.colors.WHITE};
    }
    #mul {
      font-size: 2rem;
      color: ${({ theme }) => theme.colors.GREEN};
    }
  }

  @media (max-width: 500px) {

  width: 50%;

  #price{
  font-size: 1.8rem !important;
  }
  #mcap{
  font-size: 0.9rem !important;
  }
  #mul{
  font-size: 1.8rem !important;
  }
}

@media (max-width: 520px) {

display: none; 
}

`;

export const InfoMobileRectangle = styled.div`
  display: none; /* Hide by default */

  /* Only render on screens <= 520px */
  @media (max-width: 520px) {
    display: flex; /* Show for small screens */
    flex-direction: column; /* Stack rows vertically */
    align-items: center; /* Center content horizontally */
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.LEVEL};
    border: ${borderWidths.SMALL} solid ${({ theme }) => theme.colors.LEVEL2};
    border-radius: ${borderRads.SMALL};
    width: auto; /* Adjust the size for mobile */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 0.5rem;
    padding: 0.5rem 1.25rem; /* Padding for content */

    /* Top Row: Price and Multiplier */
    .top-row {
      display: flex; /* Align price and multiplier horizontally */
      justify-content: center; /* Center horizontally */
      align-items: center; /* Ensure vertical alignment */
      gap: 0.5rem; /* Space between price and multiplier */
      width: 100%; /* Full width */
      }

    #price {
      font-size: 1.8rem; /* Adjust font size for mobile */
      color: ${({ theme }) => theme.colors.WHITE};
    }

    #mul {
      font-size: 1.8rem; /* Slightly smaller multiplier for mobile */
      color: ${({ theme }) => theme.colors.GREEN};
    }

    /* Bottom Row: Market Cap */
    .bottom-row {
      text-align: center; /* Center the text */
      width: 100%; /* Full width */
      margin-top: 0.2rem; /* Add spacing from top row */
    }

    #mcap {
      font-size: 1rem; /* Adjust size for mobile */
      color: ${({ theme }) => theme.colors.WHITE};
    }
  }
`;



export const MobileTable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.LEVEL};
  border-radius: ${borderRads.MEDIUM};
  width: 100%;
  height: auto;
  margin-top: -1.7rem;
  padding: 0.5rem 1rem;
  box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.3);
  margin-bottom: -1.7rem;

  /* Responsive Design */
  @media (min-width: 521px) {
    display: none;
    }

  .rectangle-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.3rem;
    align-items: center;

    .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;
      gap: 1.0rem;

      .right {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex: 0.1;
        gap: 0.35rem; /* Space between the chart and text */
        display: flex;

       .chart-text {
        text-align: center; /* Center the text */
        font-size: 0.6rem; /* Adjust font size */
        white-space: nowrap;
        }
      }

      .icon {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
      }

      .description {
        display: flex;
        flex-direction: row;
        gap: 0.2rem;

        .title {
          font-size: 0.8rem;
          font-weight: bold;
          color: ${({ theme }) => theme.colors.WHITE};
        }

        .subtitle {
          font-size: 0.8rem;
          color: ${({ theme }) => theme.colors.LIGHT_TEXT};
        }
      }
  }
    }

    .middle-table {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-top: 0.3rem;
      width: 100%;

      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        span {
          flex: 0.3;
          text-align: center;
        }

        .title {
          font-weight: bold;
          font-size: 0.85rem;
          color: ${({ theme }) => theme.colors.WHITE};
        }

        .text {
          font-size: 0.85rem;
          color: ${({ theme }) => theme.colors.LIGHT_TEXT};
        }
      }
    }

    .middle-table .row .title {
      white-space: nowrap;
    }
  }
`;


export const TableRectangle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.LEVEL};
  border-radius: 50px 50px 0px 0px;
  width: 90%;
  max-width: 40rem;
  padding: 1rem 1rem;
  box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.3);
  margin-top: 1rem;
  margin-bottom: -0.4rem;

  /* Responsive Design */
  @media (max-width: 700px) {
    width: 95%;
    padding: 0.8rem;
  }

  @media (max-width: 520px){
    display: none;
  }

  @media (max-width: 550px) {
    width: 100%;
    padding: 0.55rem;
  }

  .rectangle-content {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 1rem;

    .left {
      display: flex;
      align-items: center;
      flex: 0.2;
      gap: 0.5rem;

      .icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      .description {
        display: flex;
        flex-direction: column;

        .title {
          font-size: 1rem;
          font-weight: bold;
          color: ${({ theme }) => theme.colors.WHITE};
        }

        .subtitle {
          font-size: 0.85rem;
          color: ${({ theme }) => theme.colors.LIGHT_TEXT};
        }
      }

      @media (max-width: 850px) {
       .description{
        .title {
          font-size: 0.88rem; /* Smaller text for smaller screens */
        }
        .subtitle {
          font-size: 0.75rem; /* Smaller text for smaller screens */
        }
      }
  }

    }

    .middle-table {
      display: flex;
      flex-direction: column;
      flex: 0.8;
      gap: 0.5rem;

      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        span {
          flex: 1;
          text-align: center;
        }

        .title {
          font-weight: bold;
          font-size: 0.9rem;
          color: ${({ theme }) => theme.colors.WHITE};
        }

        .text {
          font-size: 0.85rem;
          color: ${({ theme }) => theme.colors.LIGHT_TEXT};
        }
      }

      @media (max-width: 850px) {
    .row {
      .title {
        font-size: 0.8rem; /* Smaller size for smaller screens */
      }
      .text {
        font-size: 0.75rem; /* Smaller size for smaller screens */
      }
    }
  }
    }

    .middle-table .row .title {
      white-space: nowrap;
    }

.right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0.2;
  gap: 0.2rem; /* Space between the chart and text */

  .chart-text {
    font-size: 0.8rem; /* Adjust font size */
    text-align: center; /* Center the text */
  }
  @media (max-width: 850px) {
    .chart-text {
      font-size: 0.7rem; /* Smaller text for smaller screens */
    }
  }
    
}
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 1rem; /* Ensure space between the text and the rectangle */
  text-align: center;

  @media (max-width: 480px){
   font-size: 0.95rem;
  }
  @media (max-width: 420px){
   font-size: 0.88rem;
   
  }
  @media (max-width: 387px){
   font-size: 0.84rem;
   
  }
`;

export const DescContainer = styled.div`
  display: flex;
  align-items: center; /* Vertically align items */
  justify-content: center; /* Center the entire content */
  background-color: ${({ theme }) => theme.colors.LEVEL};
  width: 100%;
  margin: 0 auto;
  border-radius: ${borderRads.LARGE};
  height: 3rem; /* Adjust as needed */
  margin-top: -1.7rem;
  margin-bottom: 0.2rem;
  padding: 0.5rem;
  position: relative;

  @media (min-width: 521px) {
    display: none;
  }

  /* Left and Right Sections */
  .left,
  .right {
    display: flex;
    align-items: center;
    gap: 0.5rem; /* Space between logo and name */
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
    flex: 1; /* Equal space for left and right sections */
    justify-content: flex-end; /* Right-align the left section */
  }

  .right {
    justify-content: flex-start; /* Left-align the right section */
  }

  /* VS Section */
  .vs {
    font-size: 1rem;
    text-align: center;
    color: white;
    padding: 0 1rem; /* Ensure some consistent spacing around the "vs" */
  }

  /* Logo Wrapper */
  .logo-wrapper {
    width: 2.4rem; /* Size of the logo container */
    height: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%; /* Circular logo container */
    overflow: hidden; /* Ensure logos are clipped within the circle */
  }

  /* Logo Styling */
  .logo {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure logos fit within the wrapper */
  }
`;
