export const maxLittleNumber = 0.001;
export const maxLittleNumber2 = 0.0001;

export function formatNumber(
  number,
  isLimitless = true,
  isShorted = false,
  isLittleNumber = false,
  littleNumberLimit = 6,
  noDecimals = false,
  removeDecimalsAfter1000
) {
  let numberToFormat = Number(number);

  if (noDecimals) {
    return new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 0,
      roundingMode: "floor",
    }).format(numberToFormat);
  }

  if (removeDecimalsAfter1000 && numberToFormat > 1000) {
    return new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 0,
    }).format(numberToFormat);
  }

  if (numberToFormat > 10000 && !isShorted) {
    return new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 0,
      roundingMode: "floor",
    }).format(numberToFormat);
  }

  if (numberToFormat < 1) {
    if (isLimitless) {
      return new Intl.NumberFormat("en-US", {
        maximumSignificantDigits: 2,
      }).format(numberToFormat);
    } else {
      if (
        isLittleNumber &&
        numberToFormat <
          (littleNumberLimit === 2 ? maxLittleNumber2 : maxLittleNumber)
      ) {
        return {
          decimal: Math.floor(numberToFormat),
          zeroCount: -Math.floor(Math.log10(numberToFormat) + 1),
          finalNumber: Number(
            numberToFormat
              .toString()
              .substring(0, numberToFormat.toString().indexOf("e")) * 10
          )
            .toString()
            .slice(0, 2),
        };
      } else if (isShorted) {
        return new Intl.NumberFormat("en-US", {
          maximumSignificantDigits: 1,
          notation: "compact",
          compactDisplay: "short",
        }).format(number);
      } else {
        return new Intl.NumberFormat("en-US", {
          maximumSignificantDigits: 2,
        }).format(numberToFormat);
      }
    }
  } else {
    if (isLimitless) {
      return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
        roundingMode: "floor",
      }).format(numberToFormat);
    } else if (isShorted) {
      return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 1,
        notation: "compact",
        compactDisplay: "short",
        roundingMode: "floor",
      }).format(number);
    } else {
      return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
        roundingMode: "floor",
      }).format(numberToFormat);
    }
  }
}

export function getSmallNumber(number, force) {
  if ((number <= 0 || number >= 1) && !force) {
    return "Number must be between 0 and 1 exclusive";
  }

  number = number.toFixed(20);
  const decimalPart = number.toString().split(".")[1];

  let zeroCount = 0;
  let nonZeroDigits = [];

  let finishedZeroes = false;

  for (let i = 0; i < decimalPart.length; i++) {
    if (decimalPart[i] === "0" && !finishedZeroes) {
      zeroCount++;
    } else {
      finishedZeroes = true;
      nonZeroDigits.push(decimalPart[i]);
      if (nonZeroDigits.length === 2) {
        break;
      }
    }
  }

  nonZeroDigits = nonZeroDigits.join("");

  return { zeroCount, nonZeroDigits };
}

export function epoch(date) {
  return Date.parse(date);
}

export function add3Dots(string, limit) {
  let dots = "...";
  if (string.length > limit) {
    // you can also use substr instead of substring
    string = string.substring(0, limit) + dots;
  }
  return string;
}

export function graphFormatter(
  inputNumber,
  isDetailed,
  force,
  shorted,
  realShorted
) {
  let number = Number(inputNumber);

  let finalNumber;
  let maximumFractionDigits = 2;

  if (number < 0 && !force) {
    return "";
  }

  if (number < 0 && number > -1000) {
    return new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 1,
    }).format(number);
  }

  if (number < 1) {
    if (number < 0.00000000001) {
      maximumFractionDigits = 1;
    } else if (number < 0.0000000001) {
      maximumFractionDigits = 12;
    } else if (number < 0.000000001) {
      maximumFractionDigits = 11;
    } else if (number < 0.00000001) {
      maximumFractionDigits = 10;
    } else if (number < 0.0000001) {
      maximumFractionDigits = 9;
    } else if (number < 0.000001) {
      maximumFractionDigits = 8;
    } else if (number < 0.00001) {
      maximumFractionDigits = 7;
    } else if (number < 0.0001) {
      maximumFractionDigits = 6;
    } else if (number < 0.001) {
      maximumFractionDigits = 5;
    } else if (number < 0.01) {
      maximumFractionDigits = 4;
    } else if (number < 0.1) {
      maximumFractionDigits = 3;
    } else {
      maximumFractionDigits = 2;
    }
    finalNumber = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: maximumFractionDigits,
      roundingMode: "floor",
    }).format(number);
  } else {
    if (number < 1000) {
      maximumFractionDigits = isDetailed ? 2 : shorted ? 0 : 1;
      finalNumber = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: realShorted ? 0 : maximumFractionDigits,
        roundingMode: "floor",
      }).format(number);
    } else {
      finalNumber = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: shorted ? 1 : 2,
        notation: "compact",
        compactDisplay: "short",
        roundingMode: "floor",
      }).format(number);
    }
  }

  return finalNumber;
}
